// REACT_APP_WEBSITE_URL env variable need to be passed before JS compiled
if (!process.env.REACT_APP_WEBSITE_URL)
    throw new Error('The REACT_APP_WEBSITE_URL environment variable is required but was not specified.')

const websiteUrl = process.env.REACT_APP_WEBSITE_URL

export const rootAPIUrl = websiteUrl + '/api'

export const endpoints = {

        // Main website url
        websiteURL: websiteUrl,
        // Website urls
        websiteSignInURL: websiteUrl + '/sign-in/',
        websiteSignOutURL: websiteUrl + '/sign-out/',
        websiteAboutURL: websiteUrl + '/about/',
        websiteFAQsURL: websiteUrl + '/faqs/',
        websiteHowItWorksURL: websiteUrl + '/how-it-works/',
        websiteTermsURL: websiteUrl + '/terms-conditions/',
        websitePrivacyPolicyURL: websiteUrl + '/privacy-policy/',
        tradingExternalURL: 'https://online.macquarie.com.au/personal/#/login',

        // verify auth
        authVerifyURL: rootAPIUrl + '/auth/verify/',
};


export const BREAKPOINTS= {
    GREATER_THAN_SM: '(min-width: 720px)',
    LESS_THAN_SM: '(max-width: 720px)',
    GREATER_THAN_MD: '(min-width: 1100px)',
    LESS_THAN_MD: '(max-width: 1100px)',
    GREATER_THAN_LG:'(min-width: 1500px)' ,
    LESS_THAN_LG: '(max-width: 1500)',
};

