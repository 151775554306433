import { combineReducers } from 'redux';
import * as actionTypes from './actionTypes';

const getProfileInitState = {
    data: {},
    isGettingProfile:false,
    isGetProfileSuccess:false,
    isGetProfileError:false,
};

export const getProfileStateReducer = (state = getProfileInitState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROFILE:
            return {
                ...state,
                isGettingProfile: true,
            };
        case actionTypes.GET_PROFILE_SUCCESS:
            const { type, ...rest } = action;
            return {
                ...state,
                ...rest,
            };

        case actionTypes.GET_PROFILE_FAILURE: 
            return {
                ...state,
                isGettingProfile: false,
                isGetProfileSuccess: false,
                isGetProfileError: true,
            };

        case actionTypes.GET_PROFILE_RESET:
            return {
                ...state,
                ...getProfileInitState,
            };
        default:
            return state;
    }
};

const editProfileInitState = {
    isEditingProfile:false,
    isEditProfileSuccess:false,
    isEditProfileError:false,
};

export const editProfileStateReducer = (state = editProfileInitState, action) => {
    switch (action.type) {
        case actionTypes.EDIT_PROFILE:
            return {
                ...state,
                isEditingProfile: true,
            };
        case actionTypes.EDIT_PROFILE_SUCCESS:
            return {
                ...state,
                isEditingProfile: false,
                isEditProfileSuccess: true,
                isEditProfileError: false,
            };

        case actionTypes.EDIT_PROFILE_FAILURE: 
            return {
                ...state,
                isEditingProfile: false,
                isEditProfileSuccess: false,
                isEditProfileError: true,
            };

        case actionTypes.EDIT_PROFILE_RESET:
            return {
                ...state,
                ...editProfileInitState,
            };
        default:
            return state;
    }
};

export const profileReducer = combineReducers({
    getProfileState: getProfileStateReducer,
    editProfileState: editProfileStateReducer
});
