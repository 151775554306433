import * as actionTypes from './actionTypes';

/* -----------------------------------------------------------------------------
    ACTIONS CREATORS INVESTMENT
----------------------------------------------------------------------------- */
export const showInvestmentLoader = (booleanVal) => {
    return {
        type: actionTypes.SET_INVESTMENT_LOADER,
        payload: booleanVal,
    };
};

// action to set the investment data
export const setInvestmentData = (objectVal) => {
    return {
        type: actionTypes.SET_INVESTMENT_DATA,
        payload: objectVal,
    };
};

// action to set the investment fetch success state
export const setInvestmentFetchSuccess = (booleanVal) => {
    return {
        type: actionTypes.SET_INVESTMENT_FETCH_SUCCESS,
        payload: booleanVal,
    };
};

// call the action to fetch the data
// NOTE: this will be picked up by the investment saga (sagas/investmentSaga.js)
export const getInvestmentData = () => {
    return {
        type: actionTypes.FETCH_INVESTMENT_DATA,
    };
};

/* -----------------------------------------------------------------------------
    ACTIONS CREATORS PORTFOLIO
----------------------------------------------------------------------------- */
// call the action to fetch the data
// NOTE: this will be picked up by the portfolio saga (sagas/portfolioSaga.js)
// action to hide/show the loader for the portfolio
export const showPortfolioLoader = (booleanVal) => {
    return {
        type: actionTypes.SET_PORTFOLIO_LOADER,
        payload: booleanVal,
    };
};

// action to set the portfolio data
export const setPortfolioData = (objectVal) => {
    return {
        type: actionTypes.SET_PORTFOLIO_DATA,
        payload: objectVal,
    };
};

// action to set the portfolio fetch success state
export const setPortfolioFetchSuccess = (booleanVal) => {
    return {
        type: actionTypes.SET_PORTFOLIO_FETCH_SUCCESS,
        payload: booleanVal,
    };
};

// action to set the portfolio fund value
export const setPortfolioFundValue = (numberVal) => {
    return {
        type: actionTypes.SET_PORTFOLIO_FUND_VALUE,
        payload: numberVal,
    };
};

// call the action to fetch the data
// NOTE: this will be picked up by the portfolio saga (sagas/portfolioSaga.js)
export const getPortfolioData = () => {
    return {
        type: actionTypes.FETCH_PORTFOLIO_DATA,
    };
};

/* -----------------------------------------------------------------------------
    ACTIONS CREATORS TRANSACTION
----------------------------------------------------------------------------- */

// call the action to fetch the data
// NOTE: this will be picked up by the transaction saga (sagas/transactionSaga.js)
// action to hide/show the loader for the transaction
export const showTransactionLoader = (booleanVal) => {
    return {
        type: actionTypes.SET_TRANSACTION_LOADER,
        payload: booleanVal,
    };
};

// action to set the transaction start date
export const setTransactionStartDate = (momentVal) => {
    return {
        type: actionTypes.SET_TRANSACTION_START_DATE,
        payload: momentVal,
    };
};

// action to set the transaction end date
export const setTransactionEndDate = (momentVal) => {
    return {
        type: actionTypes.SET_TRANSACTION_END_DATE,
        payload: momentVal,
    };
};

// action to set the transaction fetch success state
export const setTransactionFetchSuccess = (booleanVal) => {
    return {
        type: actionTypes.SET_TRANSACTION_FETCH_SUCCESS,
        payload: booleanVal,
    };
};

// action to set the transaction data
export const setTransactionData = (objectVal) => {
    return {
        type: actionTypes.SET_TRANSACTION_DATA,
        payload: objectVal,
    };
};

// action to reset the transaction data
export const setTransactionDataReset = (objectVal) => {
    return {
        type: actionTypes.SET_TRANSACTION_CLOSING_BALANCE_RESET,
        payload: objectVal,
    };
};


// action to set the transaction closing balance
export const setTransactionClosingBalance = (numberVal) => {
    return {
        type: actionTypes.SET_TRANSACTION_CLOSING_BALANCE,
        payload: numberVal,
    };
};

// call the action to fetch the data
// NOTE: this will be picked up by the transaction saga (sagas/transactionSaga.js)
export const getTransactionData = () => {
    return {
        type: actionTypes.FETCH_TRANSACTION_DATA,
    };
};

// call the action to clear the date range
// NOTE: this will be picked up by the transaction saga (sagas/transactionSaga.js)
export const clearDateRange = () => {
    return {
        type: actionTypes.CLEAR_DATE_RANGE,
    };
};

/* -----------------------------------------------------------------------------
    ACTIONS CREATORS ACTION
----------------------------------------------------------------------------- */

// action to hide/show the loader for the news
export const showNewsLoader = (booleanVal) => {
    return {
        type: actionTypes.SET_NEWS_LOADER,
        payload: booleanVal,
    };
};

// action to set the news data
export const setNewsData = (objectVal) => {
    return {
        type: actionTypes.SET_NEWS_DATA,
        payload: objectVal,
    };
};

/* -----------------------------------------------------------------------------
    ACTIONS CREATORS
----------------------------------------------------------------------------- */
// call the action to fetch the data
// NOTE: this will be picked up by the latest news saga
export const getNewsData = () => {
    return {
        type: actionTypes.FETCH_NEWS_DATA,
    };
};
