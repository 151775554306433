import { lazy } from 'react'

const Dashboard = lazy(() => import('__pages/Dashboard'));
const Account = lazy(() => import('__pages/Account'));
const Fund = lazy(() => import('__pages/Fund'));
const Documents = lazy(() => import('__pages/Documents'));
const Billing = lazy(() => import('__pages/Billing'));
const Requests = lazy(() => import('__pages/Requests'));
const RequestDetail = lazy(() => import('__pages/Requests/RequestDetail'));



export const routes = [
    {
        key: '0',
        path: ['/'],
        exact: true,
        component: Dashboard,
    },
    {
        key: '1',
        path: '/account',
        component: Account,
    },
    {
        key: '2',
        path: '/myfund',
        component: Fund,
    },
    {
        key: '3',
        path: '/documents',
        component: Documents,
    },
    {
        key: '4',
        path: '/billing',
        component: Billing,
    },
    {
        key: '5',
        path: '/requests',
        component: Requests,
    },
    {
        key: '6',
        path: '/request/:id',
        component: RequestDetail,
    },
];

export default routes;

