import * as actionTypes from './actionTypes';

/**
 * all actions for billing state
 *
 * Created by axle on 03/22/21
 */

// action to set billing data
export const setBillingData = (objectVal) => {
    return {
        type: actionTypes.SET_BILLING_DATA,
        payload: objectVal,
    };
};

// action to set billing loader
export const showBillingLoader = (booleanVal) => {
    return {
        type: actionTypes.SET_BILLING_LOADER,
        payload: booleanVal,
    };
};

// action to set the billing fetch success state
export const setBillingFetchSuccess = (booleanVal) => {
    return {
        type: actionTypes.SET_BILLING_FETCH_SUCCESS,
        payload: booleanVal,
    };
};

/* -----------------------------------------------------------------------------
    ACTIONS CREATORS
----------------------------------------------------------------------------- */

// call the action to fetch billing data
export const getBillingData = () => {
    return {
        type: actionTypes.FETCH_BILLING_DATA,
    };
};
