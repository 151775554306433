import {rootAPIUrl} from '__utils/constants'

// fetch doc sign link
export const docSignLinkFetchURL =  rootAPIUrl + "/document/sign-docs-url/";

// fetch document status
export const documentStatusURL =  rootAPIUrl + "/document/status/";

 // fetch docs data
export const documentsFetchURL =  rootAPIUrl + "/document/";

// download doc link
export const documentDownloadURL =  rootAPIUrl + "/document/{id}/download/";

// fetch documents signing details data
export const documentSigningDetails =  rootAPIUrl + "/document/signing-details/";

// fetch member docs data
export const memberDocumentsFetchURL = rootAPIUrl + "/member-documents/tree/";

// fetch category list (including member specific catergories) for Uploads
export const memberDocumentCategoriesFetchURL= rootAPIUrl + "/member-documents/category/list/";

// upload a document
export const memberDocumentUploadURL= rootAPIUrl + "/member-documents/{id}/add_member_document/";