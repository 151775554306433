
import * as actionTypes from './actionTypes'
import { combineReducers } from 'redux';

const notificationInitState = {
    congratulate_message: null,
    news_items: [],
    pending_actions: [],
    isLoading: false,
};

const notificationsReducer = (state = notificationInitState, action) => {
    switch (action.type) {
        case actionTypes.SET_NOTIFICATIONS_LOADER:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.SET_NOTIFICATIONS_DATA:
            return {
                ...state,
                ...action.payload,
            };

        case actionTypes.REMOVE_NOTIFICATIONS_PENDING_ACTION:
            return {
                ...state,
                pending_actions: state.pending_actions.filter((item) => item.id !== action.payload),
            };

        default:
            return state;
    }
};

const referralInitState = {
    isEmailing: false,
    response: '',
};

const referralReducer = (state = referralInitState, action) => {
    switch (action.type) {
        case actionTypes.SET_REFERRAL_LOADER:
            return {
                ...state,
                isEmailing: action.payload,
            };

        case actionTypes.SET_REFERRAL_RESPONSE:
            return {
                ...state,
                response: action.payload,
            };

        case actionTypes.SEND_REFERRAL:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export const rightMenuState = combineReducers({
    referral: referralReducer,
    notifications: notificationsReducer,
});