import { combineReducers } from 'redux';
import * as actionTypes from './actionTypes';

const initRequestDetailState = {
    isGettingRequestDetail: false,
    fetchSuccess: false,
    data: { documents: [] },
};

export const requestDetailStateReducer = (state = initRequestDetailState, action) => {
    switch (action.type) {
        // in case of `Loading` change
        case actionTypes.SET_REQUEST_DETAIL_LOADER:
            return {
                ...state,
                isGettingRequestDetail: action.payload,
            };

        // in case of `Data` change
        case actionTypes.SET_REQUEST_DETAIL_DATA:
            return {
                ...state,
                data: action.payload,
            };

        case actionTypes.SET_REQUEST_DETAIL_FETCH_SUCCESS:
            return {
                ...state,
                fetchSuccess: action.payload,
            };

        case actionTypes.RESET_REQUEST_DETAIL:
            return {
                ...initRequestDetailState,
            };

        default:
            return state;
    }
};

const initRequestToCompleteState = {
    isRequestCompleteLoader: false,
    requestToCompleteSuccess: false,
};

export const requestToCompleteStateReducer = (state = initRequestToCompleteState, action) => {
    switch (action.type) {
        // in case of `Loading` change
        case actionTypes.SET_REQUEST_TO_COMPLETE_LOADER:
            return {
                ...state,
                isRequestCompleteLoader: action.payload,
            };

        case actionTypes.SET_REQUEST_TO_COMPLETE_SUCCESS:
            return {
                ...state,
                requestToCompleteSuccess: action.payload,
            };

        case actionTypes.RESET_REQUEST_TO_COMPLETE:
            return {
                ...initRequestToCompleteState,
            };

        default:
            return state;
    }
};

const initDocumentToCompleteState = {
    isDocumentToCompleteLoader: false,
    documentToCompleteSuccess: false,
};

export const documentToCompleteStateReducer = (state = initDocumentToCompleteState, action) => {
    switch (action.type) {
        // in case of `Loading` change
        case actionTypes.SET_DOCUMENT_TO_COMPLETE_LOADER:
            return {
                ...state,
                isDocumentToCompleteLoader: action.payload,
            };

        case actionTypes.SET_DOCUMENT_TO_COMPLETE_SUCCESS:
            return {
                ...state,
                documentToCompleteSuccess: action.payload,
            };

        case actionTypes.RESET_DOCUMENT_TO_COMPLETE:
            return {
                ...initDocumentToCompleteState,
            };

        default:
            return state;
    }
};

const initRemoveDocFileState = {
    fileKey: '',
    isRemoveDocFileLoader: false,
    removeDocFileSuccess: false,
};

export const removeDocFileStateReducer = (state = initRemoveDocFileState, action) => {
    switch (action.type) {
        case actionTypes.GET_REMOVE_DOCUMENT_FILE:
            return {
                ...state,
                fileKey: action.payload.fileKey,
            };

        // in case of `Loading` change
        case actionTypes.SET_REMOVING_DOCUMENT_FILE_LOADER:
            return {
                ...state,
                isRemoveDocFileLoader: action.payload,
            };

        case actionTypes.SET_REMOVE_DOCUMENT_FILE_SUCCESS:
            return {
                ...state,
                removeDocFileSuccess: action.payload,
            };

        case actionTypes.RESET_REMOVE_DOCUMENT_FILE:
            return {
                ...initRemoveDocFileState,
            };

        default:
            return state;
    }
};

export const requestsDetailsReducer = combineReducers({
    requestDetailState: requestDetailStateReducer,
    requestToCompleteState: requestToCompleteStateReducer,
    documentToCompleteState: documentToCompleteStateReducer,
    removeDocFileState: removeDocFileStateReducer,
});
