
export const getTaxYears = () => {
    let date = new Date()
    let taxYears = []
    let taxYear = date.getFullYear();

    taxYears.push({ value: -1, label: 'General (not related to a tax year)' })

    if (date.getMonth() > 6) {
        taxYear++
    }

    for(var i = 0; i < 5; i++) {
        taxYears.push({
            value: i,
            label: (taxYear-i-1).toString() + "-" + (taxYear-i).toString()}
        )
    }

    return taxYears;
}


export const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
}