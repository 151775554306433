import { getTaxYears } from '../__utils/helpers';
import * as actionTypes from './actionTypes';

const initState = {
    docSignStatus: false,
    docSignLoading: false,
    documents: [],
    documentsLoading: false,
    docSignDetails: [],
    memberDocumentsloading: undefined,
    selectableTaxYears: getTaxYears(),
    memberDocDetails: [],
    memberDocFolderLookup: {},
};

export const documentsState = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SET_DOC_SIGN_STATUS:
            return {
                ...state,
                docSignStatus: action.payload,
            };

        case actionTypes.SET_DOCUMENTS_DATA:
            return {
                ...state,
                documents: action.payload,
            };

        case actionTypes.SET_DOCUMENTS_LOADER:
            return {
                ...state,
                documentsLoading: action.payload,
            };

        case actionTypes.SET_DOC_SIGN_STATUS_LOADER:
            return {
                ...state,
                docSignLoading: action.payload,
            };

        case actionTypes.SET_DOCUMENTS_FETCH_SUCCESS:
            return {
                ...state,
                fetchSuccess: action.payload,
            };

        case actionTypes.SET_DOC_SIGN_DETAILS:
            return {
                ...state,
                docSignDetails: action.payload,
            };

        case actionTypes.SET_MEMBER_DOC_DETAILS:
            return {
                ...state,
                memberDocDetails: action.payload,
            };

        case actionTypes.SET_MEMBER_DOC_CATEGORIES:
            return {
                ...state,
                memberDocCategories: action.payload,
            };

        case actionTypes.SET_MEMBER_DOC_FOLDER_LOOKUP:
            return {
                ...state,
                memberDocFolderLookup: action.payload,
            };

        default:
            return state;
    }
};

// function getDocuments(data){
//     // Need id and name of each doc
//     const rawDocData = data['envelope_documents']
//     let documents = []

//     rawDocData.forEach(
//         (ele, index)=>{
//             const document = { 'id':  ele['document_id'], 'name':  ele['name'], 'modified': '-' }
//             documents.push(document)
//         }
//     )
//     return documents
// }


