import { takeEvery, put ,call} from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
// import action creators
import * as actions from "./actions";
import * as api from "../__api";
import { substitute,isEmpty,request } from "__utils/utilities";
import Cookies from 'js-cookie'


/* -----------------------------------------------------------------------------
    WATCHER & WORKER SAGA TO FETCH THE NOTIFICATIONS DATA
----------------------------------------------------------------------------- */

// watcher saga for fetching the notifications data
export function* getNotificationsSaga() {
    yield takeEvery(actionTypes.FETCH_NOTIFICATIONS_DATA, getNotifications);
}

// worker saga for fetching the notifications data
function* getNotifications() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    // set the endpoint
    const requestURL = api.notificationFetchURL;

    try {
        // show the loader
        yield put(actions.showNotificationsLoader(true));

        // get the response promise object
        const response = yield call(request, requestURL, headers);

        if (!isEmpty(response.data)) {
            const notificationsData = response.data;

            // set the notifications data
            yield put(actions.setNotificationsData(notificationsData));
        }
    } catch (error) {
        // TODO : ??
    } finally {
        // hide the loader regardless
        yield put(actions.showNotificationsLoader(false));
    }
}

/* -----------------------------------------------------------------------------
    WATCHER & WORKER SAGA TO DISMISS NOTIFICATION
----------------------------------------------------------------------------- */

// watcher saga for dismiss notification
export function* dismissNotificationSaga() {
    yield takeEvery(actionTypes.DISMISS_NOTIFICATION, dismissNotification);
}

// worker saga for dismiss notification
function* dismissNotification(action) {
    const id = action.payload;

    // set the headers
    const headers = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
        },
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(id),
    };

    // set the endpoint
    const requestURL = substitute(api.notificationDismissURL, { id });

    try {
        // get the response promise object
        const response = yield call(request, requestURL, headers);

        if (!isEmpty(response.data)) {
            const notificationsData = response.data;

            // set the notifications data
            yield put(actions.setNotificationsData(notificationsData));
        }
    } catch (error) {
        // TODO : ??
    } finally {
    }
}

/* -----------------------------------------------------------------------------
    WATCHER & WORKER SAGA TO SEND REFERRAL EMAIL
----------------------------------------------------------------------------- */

// watcher saga for send referral email
export function* watchSendReferral() {
    yield takeEvery(actionTypes.SEND_REFERRAL, workerSendReferral);
}

// worker saga for send referral
function* workerSendReferral(action) {
    const data = action.payload;
    const id = data['id'];
    const email = data['email_string'];

    // set the headers
    const headers = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
        },
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(email),
    };

    // set the endpoint
    const requestURL = substitute(api.sendReferralURL, { id: id });

    // show the loader
    yield put(actions.showReferralLoader(true));

    try {
        // get the response promise object
        const response = yield call(request, requestURL, headers);

        if (!isEmpty(response.data)) {
            // return email response
            yield put(actions.setReferralResponse(response.data));
        }
    } catch (error) {
    } finally {
        // Remove loader
        yield put(actions.showReferralLoader(false));
    }
}