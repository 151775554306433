import { takeEvery, call, put } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import * as api from '../__api'
import { request, isEmpty } from "../../../__utils/utilities";
// import action creators
import * as actions from "./actions";

/* -----------------------------------------------------------------------------
    WATCHER & WORKER SAGA TO FETCH THE FUND DATA
----------------------------------------------------------------------------- */

// watcher saga for fetching the fund data
export function* getFundSaga() {
    yield takeEvery(actionTypes.FETCH_FUND_DATA, getFund);
}

// worker saga for fetching the fund data
function* getFund() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    // set the endpoint
    const requestURL = api.fundFetchURL;

    try {
        // show the loader
        yield put(actions.showFundLoader(true));

        // get the response promise object
        const response = yield call(request, requestURL, headers);

        if (!isEmpty(response.data)) {
            // set the fund data
            yield put(actions.setFundInfoData(response.data));
            yield put(actions.setMembersTrusteesInfoData(response.data));
            yield put(actions.setCompanyDetailsData(response.data));
        }
    } catch (error) {
    } finally {
        // hide the loader regardless
        yield put(actions.showFundLoader(false));
    }
}

// watcher saga for fetching the basic fund data
export function* getBasicFundSaga() {
    yield takeEvery(actionTypes.FETCH_BASIC_FUND_DATA, getBasicFund);
}

// worker saga for fetching the basic fund data
function* getBasicFund() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    // set the endpoint
    const requestURL = api.basicFundFetchURL;

    try {
        // get the response promise object
        const response = yield call(request, requestURL, headers);

        if (!isEmpty(response.data)) {
            // set the fund data
            yield put(actions.setBasicFundInfoData(response.data));
        }
    } catch (error) {}
}
