import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {endpoints} from '__utils/constants'
import { useLocation } from 'react-router-dom';

// component import
import Avatar from '__pages/Account/Avatar'
import MenuItem from './MenuItem/'
import AppLink from 'components/AppLink'
import AppButton from 'components/AppButton'
import Footer from 'components/Footer'

// menu items import
import menuItems from './menuItems'
import topbarMenuItems from '../TopBar/menuItems'

// icon imports
import CloseIcon from 'assets/svg/close.svg'

// style import
import './style.scss'
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINTS } from '__utils/constants';

//redux
import {  useSelector } from 'react-redux';
import {requestsSlr} from '__pages/Requests/__redux/selectors'

const LeftMenu = (props) => {
    const {onClickLeftMenu, layoutProps} = props
    const {openLeftMenu,onTour,onTourHighlight} = layoutProps
    const location = useLocation();
    const { pathname } = location;
    const LESS_THAN_LG = useMediaQuery({ query: BREAKPOINTS.LESS_THAN_LG });
    const LESS_THAN_MD = useMediaQuery({ query: BREAKPOINTS.LESS_THAN_MD });
    const GREATER_THAN_MD = useMediaQuery({ query: BREAKPOINTS.GREATER_THAN_MD });
    const { data: requestsData } = useSelector(requestsSlr)
 
    const onMenuClick = () => {
        //close the left menu on clicking menu item in mobile and tablet
        LESS_THAN_LG && onClickLeftMenu()
    };

    const signOutClick = () => {
        //close the left menu on clicking sign out button in mobile and tablet
        LESS_THAN_LG && onClickLeftMenu()
    };

    const dynClass = cx('menu left-menu', { 'left-menu--open': openLeftMenu });
   
    return (
        <div className={dynClass}>
            <div className="left-menu__wrapper">
                {/* close icon (only tablet and mobile view) */}
                <img
                    src={CloseIcon}
                    className="left-menu__close-icon"
                    alt="Left menu close icon"
                    onClick={() => onClickLeftMenu()}
                />

                {/* avatar component */}
                <Avatar />

                {/* creating menu items from array */}
                <div className="left-menu__menu-items">
                    {menuItems.map((item, index) =>  {

                        if(requestsData.length === 1 && item.menuText === 'REQUESTS') {
                            return <MenuItem
                            key={index}
                            svgIcon={item.menuIcon}
                            isExternal={item.isExternal}
                            openInNewTab={item.openInNewTab}
                            menuText={item.menuText}
                            route={`/request/${requestsData[0].id}`}
                            pathname={pathname}  
                            onClick={() => onMenuClick()}
                            tourId={item.tourId}
                            onTour={onTour}
                            onTourHighlight={onTourHighlight}
                            />
                        }
                       
                            return <MenuItem
                            key={index}
                            svgIcon={item.menuIcon}
                            isExternal={item.isExternal}
                            openInNewTab={item.openInNewTab}
                            menuText={item.menuText}
                            route={item.route}
                            pathname={pathname}  
                            onClick={() => onMenuClick()}
                            tourId={item.tourId}
                            onTour={onTour}
                            onTourHighlight={onTourHighlight}
                            />

                        }        
                    )}
                </div>

                {/* creating top menu items from array (only show on table and mobile)*/}
                {(LESS_THAN_MD || LESS_THAN_LG) && (
                    <div className="left-menu__top-menu-items">
                        {topbarMenuItems.map((item, index) =>
                            // don't display the sign-out item (index 4)
                            index < 4 ? (
                                <MenuItem
                                    key={index}
                                    svgIcon={item.menuIcon}
                                    isExternal={item.isExternal}
                                    openInNewTab={item.openInNewTab}
                                    menuText={item.menuText}
                                    route={item.route}
                                    pathname={pathname}
                                    onClick={() => onMenuClick(item)}
                                />
                            ) : null
                        )}

                        {/* sign out button */}
                        <div className="left-menu__sign-out-button">
                            <AppLink isExternal={true} route={endpoints.websiteSignOutURL}>
                                <AppButton buttonText="SIGN OUT" onClick={() => signOutClick} size="big" color="blue" />
                            </AppLink>
                        </div>
                    </div>
                )}

                {/* include footer (only show on desktop)*/}
                {GREATER_THAN_MD && <Footer />}
            </div>
        </div>
    );
};

// define the prop types
LeftMenu.propTypes = {
    layoutProps: PropTypes.object,
    onClickLeftMenu: PropTypes.func,
};



export default LeftMenu;
