import { combineReducers } from 'redux';
import { requestsDetailsReducer } from '../RequestDetail/__redux/reducers';
import * as actionTypes from './actionTypes';

const initState = {
    isGettingRequests: false,
    fetchSuccess: false,
    data: [],
};

export const requestsStateReducer = (state = initState, action) => {
    switch (action.type) {
        // in case of `Loading` change
        case actionTypes.SET_REQUESTS_LOADER:
            return {
                ...state,
                isGettingRequests: action.payload,
            };

        // in case of `Data` change
        case actionTypes.SET_REQUESTS_DATA:
            return {
                ...state,
                data: action.payload,
            };

        case actionTypes.SET_REQUESTS_FETCH_SUCCESS:
            return {
                ...state,
                fetchSuccess: action.payload,
            };

        case actionTypes.RESET_REQUESTS_DATA:
            return {
                ...initState,
            };

        default:
            return state;
    }
};

const fetchRequestsSummaryInitState = {
    fetchingRequestsSummary: false,
    fetchRequestsSummarySuccess: false,
    requestsSummary: [],
};

export const fetchRequestsSummaryReducer = (state = fetchRequestsSummaryInitState, action) => {
    switch (action.type) {
        // in case of `Loading` change
        case actionTypes.FETCH_REQUESTS_SUMMARY_LOADER:
            return {
                ...state,
                fetchingRequestsSummary: action.payload,
            };

        // in case of `Data` change
        case actionTypes.SET_REQUESTS_SUMMARY_DATA:
            return {
                ...state,
                data: action.payload,
            };

        case actionTypes.SET_REQUESTS_SUMMARY_DATA_SUCCESS:
            return {
                ...state,
                fetchRequestsSummarySuccess: action.payload,
            };

        case actionTypes.RESET_REQUESTS_SUMMARY_DATA:
            return {
                ...initState,
            };

        default:
            return state;
    }
};

export const requestsReducer = combineReducers({
    requestsState: requestsStateReducer,
    requestsDetails: requestsDetailsReducer,
    fetchRequestsSummary: fetchRequestsSummaryReducer,
});
