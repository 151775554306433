import { call, put,takeLatest} from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import * as api from '../__api'
import { request } from "__utils/utilities";
import Cookies from 'js-cookie'

const getProfile = function* () {

    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    const requestURL = api.profileFetchURL;

    try {
        const response = yield call(request, requestURL, headers);
        const { status, data } = response;

        if (status === 200) {
            const { firstName, phone, isNew, email, id } = data;

            const profileData = {
                first_name: firstName,
                is_new: isNew,
                phone,
                email: email,
                id,
            };

            const _profileData = {
                data:profileData,
                isGettingProfile: false,
                isGetProfileSuccess: true,
                isGetProfileError: false,
            };
            yield put({
                type: actionTypes.GET_PROFILE_SUCCESS,
                ..._profileData,
            });
        } else if (status === 302 || status === 403) {
            window.location.assign(process.env.REACT_APP_APP_URL );
        } else {
            yield put({ type: actionTypes.GET_PROFILE_FAILURE });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_PROFILE_FAILURE });
    }
};

export const getProfileSaga = function* () {
    yield takeLatest(actionTypes.GET_PROFILE, getProfile);
};


const editBrand = function* (action) {
    const { payload:editedProfileData } = action;
    const headers = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
        },
        data: editedProfileData,
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(editedProfileData),
    };

    // set the endpoint
    const requestURL = api.profileUpdateURL;

    try {
        const response = yield call(request, requestURL, headers);
        const {status,data:newProfile} = response
        if (status === 200) {

            const { firstName, phone, isNew, email, id } = newProfile;

            const _newProfile = {
                first_name: firstName,
                is_new: isNew,
                phone,
                email: email,
                id,
            };
    
            yield put({
                type: actionTypes.GET_PROFILE_SUCCESS,
                data: _newProfile,
            });

            yield put({
                type: actionTypes.EDIT_PROFILE_SUCCESS,
            });
        } else {
            yield put({ type: actionTypes.EDIT_PROFILE_FAILURE });
        }
    } catch (error) {
        yield put({ type: actionTypes.EDIT_PROFILE_FAILURE });
    }
};

export const editBrandSaga = function* () {
    yield takeLatest(actionTypes.EDIT_PROFILE, editBrand);
};