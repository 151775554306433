import { takeEvery, call, put } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import * as api from '../__api'
import { request, isEmpty } from "../../../__utils/utilities";
// import action creators
import * as actions from "./actions";

// watcher saga for fetching the billing data
export function* getBillingDataSaga() {
    yield takeEvery(actionTypes.FETCH_BILLING_DATA, getBillingData);
    
}

// worker saga for fetching biling data
function* getBillingData() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    // set the endpoint
    const requestURL = api.billingFetchURL;

    try {
        // show the loader
        yield put(actions.showBillingLoader(true));

        // get the response promise object
        const response = yield call(request, requestURL, headers);

        if (!isEmpty(response.data.data)) {
            const billingDetails = response.data.data.payment;
            yield put(actions.setBillingData(billingDetails));
        }
    } catch (error) {
        // set the success flag to false
        yield put(actions.setBillingFetchSuccess(false));
    } finally {
        // hide the loader regardless
        yield put(actions.showBillingLoader(false));
    }
}