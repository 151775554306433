import React from 'react'
// style import
import EmptyData from '../EmptyData'
import './style.scss'

const PageNotFound = () => {
  return (
      <div className="page-not-found-container">
          <EmptyData
              headerText="Page Not Found "
              pageMsg="404"
              notificationText="Sorry, the page you visited does not exist."
          />
      </div>
  );
};

export default PageNotFound;
