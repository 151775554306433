import { createStore, applyMiddleware, compose } from 'redux'
//import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from "history";
import throttle from 'lodash/throttle'

import rootSaga from 'rootSaga'
import { loadState, saveState } from './__utils/localStorage'
import rootReducer from 'rootReducer'

// create a history
export const history = createBrowserHistory();

// create middleware for the sagas
const sagaMiddleware = createSagaMiddleware();

// create the logger middleware
//const loggerMiddleWare = createLogger()

// create the middleware array
const middleware = [
    thunk,
    //loggerMiddleWare,
    sagaMiddleware,
];

const enhancers = [];

// ONLY FOR DEBUGGING
if (window.devToolsExtension) {
    // Chrome Redux DevTool extension
    enhancers.push(window.devToolsExtension());
}

// create the store
const store = createStore(
    rootReducer,
    loadState(),
    compose( applyMiddleware(...middleware), ...enhancers)
);

// run the root saga
sagaMiddleware.run(rootSaga);

// sync with localstorage
// NOTE: we use the tiny library `throttle` from the package `lodash` that
// guarantees we don't sync to localStorage more than once per second
store.subscribe(
    throttle(() => {
        // store the user data
        saveState({
            user: store.getState().user,
        });
    }, 1000)
);

// ONLY FOR DEBUGGING - ACCESS STORE IN CONSOLE window.store.dispatch()
// NOTE: change the execution environment in the Chrome console from the default <top frame> to <debuggerWorker.js>
//window.reduxStore = store

export default store;

