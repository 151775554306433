import React from 'react'
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

const AppLink = ({ isExternal, route, openInNewTab, children, className, onClick }) => {
    return isExternal ? (
        <a href={route} className={className} onClick={onClick} {...(openInNewTab && { target: '_blank' })}>
            {children}
        </a>
    ) : (
        <Link to={route} className={className} onClick={onClick}>
            {children}
        </Link>
    );
};

// define the prop types
AppLink.propTypes = {
    isExternal: PropTypes.bool,
    openInNewTab: PropTypes.bool,
    route: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
};

export default AppLink;
