import { rootAPIUrl } from '__utils/constants';

// fetch notifications data
export const notificationFetchURL = rootAPIUrl + '/notifications/';

// dimiss/complete a notification
export const notificationDismissURL = rootAPIUrl + '/notifications/{id}/dismiss/';

// send a referral email
export const sendReferralURL = rootAPIUrl + '/referral/{id}/send/';
