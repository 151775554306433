import React from 'react'
import PropTypes from 'prop-types'

// icon imports
import SearchIcon from 'assets/svg/searching.svg'

// style import
import './style.scss'


const EmptyData = ({ headerText, notificationText, pageMsg }) => {
    const defaultNotificationText =
        "In order to see data here you must sign your SMSF documentation, roll over funds into your new CMA and start investing your SMSF's money.";
    const defaultPageMsg = 'NO DATA AVAILABLE';
    return (
        <div className="empty-data">
            <p className="empty-data__header-text">{headerText}</p>

            <img src={SearchIcon} className="empty-data__icon" alt="Empty data icon" />

            <p className="empty-data__nodata-text">{pageMsg ? pageMsg : defaultPageMsg}</p>

            <p className="empty-data__notification-text">
                {notificationText ? notificationText : defaultNotificationText}
            </p>
        </div>
    );
};

// define the prop types
EmptyData.propTypes = {
    headerText: PropTypes.string,
};

export default EmptyData;
