import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import AppLink from 'components/AppLink'

// style import
import './style.scss'


const TopBarMenuItem = ({
    leftIcon,
    leftIconAlt,
    leftIconClass,
    rightIcon,
    rightIconAlt,
    rightIconClass,
    menuItem,
    pathname,
    route,
    onClick,
    isExternal,
    openInNewTab,
}) => {
    // check of prop menuText exist
    const menuText = menuItem ? menuItem.menuText : null;

    // check if current path equals the menuItem.route and if yes set CSS active
    const dynClass = cx('top-bar-menu-item', { 'top-bar-menu-item--active': menuItem.route === pathname });

    return (
        <AppLink
            isExternal={menuItem.isExternal}
            onClick={onClick}
            route={menuItem.route}
            openInNewTab={menuItem.openInNewTab}
            className={dynClass}
        >
            <span>
                {leftIcon && <img src={leftIcon} className={leftIconClass} alt={leftIconAlt} />}
                <span className="top-bar-menu-item__text">{menuText}</span>
                {rightIcon && <img src={rightIcon} className={rightIconClass} alt={rightIconAlt} />}
            </span>
        </AppLink>
    );
};

// define the prop types
TopBarMenuItem.propTypes = {
    menuItem: PropTypes.object,
    pathname: PropTypes.string,
    onClick: PropTypes.func,
};

export default TopBarMenuItem;
