
import * as actionTypes from './actionTypes'

const initState = {
    billingloading: false,
    fetchSuccess: true,
    data: [],
};

export const billingState = (state = initState, action) => {
    switch (action.type) {
        // in case of `Loading` change
        case actionTypes.SET_BILLING_LOADER:
            return {
                ...state,
                billingloading: action.payload,
            };

        // in case of `Data` change
        case actionTypes.SET_BILLING_DATA:
            return {
                ...state,
                data: action.payload,
            };

        // in case of `iInvestmentSuccess` change
        case actionTypes.SET_BILLING_FETCH_SUCCESS:
            return {
                ...state,
                fetchSuccess: action.payload,
            };

        default:
            return state;
    }
};
