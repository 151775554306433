import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {BrowserRouter as Router} from 'react-router-dom'

import store from 'store'
import { request } from '__utils/utilities'
import {endpoints} from '__utils/constants'
import reportWebVitals from './reportWebVitals';
import 'assets/css/custom_antd.css';
import 'assets/css/main.scss'
import App from './App'

// Making api call to verify user is authenticated.
// Request will return 403 for non-authenticated user and get redirected
// to sign-in page.

request(endpoints.authVerifyURL, {
    method: 'GET',
    credentials: "include",
    mode: 'cors',
}).then(() => {
    // Is authenticated. Start rendering page
    ReactDOM.render(
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>,
        document.getElementById('root')
    )
}).then(function(response) {
    console.log("CHECK CACHE")
    caches.keys().then(function(names) {
        for (let name of names) {
            
            console.log('Found: ' + name)

            if (name.startsWith('sw-precache-v3-sw-precache-webpack-plugin-https://member.staging2.selfmadesuper.com.au') || name.startsWith('sw-precache-v3-sw-precache-webpack-plugin-https://member.selfmade.com.au')) {     
                console.log('Deleting');
                caches.delete(name);
                console.log('Deleted Successfully');
            }
        }
    });
})
.catch(() => {
    console.error('Not authenticated');
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
