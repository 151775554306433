 import { useSelector } from 'react-redux';
 import { useHistory } from 'react-router-dom';
 import { getProfileSlr } from '../__redux/selectors';
 // style import
 import './style.scss';
 
 const Avatar = () => {
     const profile = useSelector(getProfileSlr);
     const { data: profileData } = profile;
     const history = useHistory();
 
     const onClickUpdateProfile = (e) => {
         // do not refresh page
         e.preventDefault();
         e.stopPropagation();
         // go to route
         history.push('/account');
     };
     return (
         <div>
             <div className="avatar">
                 {/* container for the avatar icon, name & link */}
                 <div className="avatar__info-container">
                     <div className="avatar__info-container__profile-container">
                         <p className="avatar__info-container__avatar-name"> {profileData.first_name} </p>
                         <a
                             href="/"
                             className="avatar__info-container__profile-container__update"
                             onClick={onClickUpdateProfile}
                         >
                             Update my profile
                         </a>
                     </div>
                 </div>
             </div>
         </div>
     );
 }
 
 export default Avatar;
 