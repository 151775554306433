import {rootAPIUrl} from '__utils/constants'


// fetch url for the list of Investment
export const investmentFetchURL =   rootAPIUrl + "/accounting/asset_position/{todayDate}/";

// fetch url for Investment download
export const investmentDownloadURL =   rootAPIUrl + "/accounting/asset_position/download/";

// fetch url for the list of Portfolio value
export const portfolioFetchURL = rootAPIUrl + "/accounting/fund_value/{startDate}/{endDate}/";

// fetch url for Portfolio download
export const portfolioDownloadURL = rootAPIUrl + "/accounting/investment_summary/download/";

// fetch url for the list of transactions
export const transactionFetchURL = rootAPIUrl + "/accounting/transaction_details/{startDate}/{endDate}/";

// fetch url for the News
export const newsFetchURL = rootAPIUrl + "/latest-news/";

