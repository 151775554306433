import React from 'react'
import PropTypes from 'prop-types'
// Joyride imports
import Joyride from "react-joyride"
import { ACTIONS, EVENTS } from 'react-joyride/es'
import {tourState} from './__utils/constants'
import { useLocation } from 'react-router-dom';
import './style.scss'
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINTS } from '__utils/constants';

const TourDemo = (props) => {
    const {onOpenCloseRightMenu , onSetTour, onSetTourHighLight} = props;
    const location = useLocation();
    const [_tourState, setTourState] = React.useState(tourState);
    const { pathname } = location;
    //selector
    const GREATER_THAN_LG = useMediaQuery({ query: BREAKPOINTS.GREATER_THAN_MD });

    React.useEffect(() => {
        if (pathname === '/' && GREATER_THAN_LG) {
            setTourState({ ..._tourState, run: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const handleJoyrideCallback = (data) => {
        const { action, index, type } = data;
        
        if (type === EVENTS.TOUR_START && _tourState.run) {
            setTimeout(() => {
                onSetTour(true)
            }, 850);
         
        } else if (type === EVENTS.TOUR_END && _tourState.run) {
            // End the tour
            setTourState({ ..._tourState, run: false });
            onSetTour(false)
        } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 1) {
            setTourState({ ..._tourState, run: false, loading: true });
           
            setTimeout(() => {
                setTourState({
                    ..._tourState,
                    loading: false,
                    run: true,
                    stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
                });
                onOpenCloseRightMenu(false)
            }, 850);

        } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV && index === 3) {
            setTourState({ ..._tourState, run: false, loading: true });
            onOpenCloseRightMenu(true)
            setTimeout(() => {
                setTourState({
                    ..._tourState,
                    loading: false,
                    run: true,
                    stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
                });
            }, 850);
        } else if (type === EVENTS.STEP_AFTER && index === 8) {
            // End the tour
            setTourState({ ..._tourState, run: false });
            setTimeout(() => {
                onOpenCloseRightMenu(false);
                onSetTour(false)
            }, 850);
           
        } else if (type === EVENTS.STEP_AFTER) {
            setTourState({ ..._tourState, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
        } else if (type === EVENTS.TOOLTIP_CLOSE) {
            setTourState({ ..._tourState, stepIndex: index + 1 });
        }

        if (index === 2) {
            onSetTourHighLight('DASHBOARD')
        } else if (index === 3) {
            onSetTourHighLight('REQUESTS')
        } else if (index === 4) {
            onSetTourHighLight('MY FUND')
        } else if (index === 5) {
            onSetTourHighLight('TRADING')
        } else if (index === 6) {
            onSetTourHighLight('DOCUMENTS')
        } else if (index === 7) {
            onSetTourHighLight('BILLING')
        } else {
            onSetTourHighLight('')
            
        }
    };

    const { ...joyrideProps } = _tourState;
    const _props = {
        ...joyrideProps,
    };

    return <Joyride scrollToFirstStep showSkipButton {..._props} callback={handleJoyrideCallback} />;
};

// define the prop types
TourDemo.propTypes = {
    onSetTourHighLight: PropTypes.func,
    onOpenCloseRightMenu:PropTypes.func,
    onSetTour:PropTypes.func,
    
};


export default TourDemo;
