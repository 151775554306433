import * as actionTypes from './actionTypes';

// action to set requestDetail data
export const setRequestDetailCtr = (objectVal) => {
    return {
        type: actionTypes.SET_REQUEST_DETAIL_DATA,
        payload: objectVal,
    };
};

// action to set requestDetail loader
export const showRequestDetailLoaderCtr = (booleanVal) => {
    return {
        type: actionTypes.SET_REQUEST_DETAIL_LOADER,
        payload: booleanVal,
    };
};

// action to set the requestDetail fetch success state
export const setRequestDetailFetchSuccessCtr = (booleanVal) => {
    return {
        type: actionTypes.SET_REQUEST_DETAIL_FETCH_SUCCESS,
        payload: booleanVal,
    };
};

/* -----------------------------------------------------------------------------
     ACTIONS CREATORS
 ----------------------------------------------------------------------------- */

// call the action to fetch requestDetail data
export const getRequestDetailCtr = (data) => {
    return {
        type: actionTypes.FETCH_REQUEST_DETAIL_DATA,
        payload: data,
    };
};

export const resetRequestDetailCtr = () => {
    return {
        type: actionTypes.RESET_REQUEST_DETAIL,
    };
};

//Mark Request as Complete

export const getToCompleteRequestCtr = (data) => {
    return {
        type: actionTypes.GET_REQUEST_TO_COMPLETE_DATA,
        payload: data,
    };
};

// action to set requestTOComplete loader
export const showRequestToCompleteLoaderCtr = (booleanVal) => {
    return {
        type: actionTypes.SET_REQUEST_TO_COMPLETE_LOADER,
        payload: booleanVal,
    };
};

// action to set the requestTOComplete fetch success state
export const setRequestToCompleteSuccessCtr = (booleanVal) => {
    return {
        type: actionTypes.SET_REQUEST_TO_COMPLETE_SUCCESS,
        payload: booleanVal,
    };
};

export const resetRequestToCompleteCtr = () => {
    return {
        type: actionTypes.RESET_REQUEST_TO_COMPLETE,
    };
};

//Mark Document as Complete

export const getToCompleteDocumentCtr = (data) => {
    return {
        type: actionTypes.GET_DOCUMENT_TO_COMPLETE_DATA,
        payload: data,
    };
};

// action to set documentTOComplete loader
export const showDocumentToCompleteLoaderCtr = (booleanVal) => {
    return {
        type: actionTypes.SET_DOCUMENT_TO_COMPLETE_LOADER,
        payload: booleanVal,
    };
};

// action to set the documentTOComplete fetch success state
export const setDocumentToCompleteSuccessCtr = (booleanVal) => {
    return {
        type: actionTypes.SET_DOCUMENT_TO_COMPLETE_SUCCESS,
        payload: booleanVal,
    };
};

export const resetToCompleteDocumentCtr = () => {
    return {
        type: actionTypes.RESET_DOCUMENT_TO_COMPLETE,
    };
};

//Remove Doc File

export const toRemoveDocFileCtr = (data) => {
    return {
        type: actionTypes.GET_REMOVE_DOCUMENT_FILE,
        payload: data,
    };
};

// action to set documentTOComplete loader
export const showRemoveDocFileLoaderCtr = (booleanVal) => {
    return {
        type: actionTypes.SET_REMOVING_DOCUMENT_FILE_LOADER,
        payload: booleanVal,
    };
};

// action to set the documentTOComplete fetch success state
export const setRemoveDocFileSuccessCtr = (booleanVal) => {
    return {
        type: actionTypes.SET_REMOVE_DOCUMENT_FILE_SUCCESS,
        payload: booleanVal,
    };
};

export const resetRemoveDocFileCtr = () => {
    return {
        type: actionTypes.RESET_REMOVE_DOCUMENT_FILE,
    };
};
