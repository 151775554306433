// get requestsReducer
export const SET_REQUESTS_LOADER= 'SET_REQUESTS_LOADER'; 
export const FETCH_REQUESTS_DATA= 'FETCH_REQUESTS_DATA';
export const SET_REQUESTS_DATA= 'SET_REQUESTS_DATA';
export const SET_REQUESTS_FETCH_SUCCESS= 'SET_REQUESTS_FETCH_SUCCESS';
export const RESET_REQUESTS_DATA= 'RESET_REQUESTS_DATA';

// get requests summary
export const FETCH_REQUESTS_SUMMARY_LOADER = 'FETCH_REQUESTS_SUMMARY_LOADER'
export const FETCH_REQUESTS_SUMMARY_DATA = 'FETCH_REQUESTS_SUMMARY_DATA'
export const SET_REQUESTS_SUMMARY_DATA = 'SET_REQUESTS_SUMMARY_DATA'
export const SET_REQUESTS_SUMMARY_DATA_SUCCESS = 'SET_REQUESTS_SUMMARY_DATA_SUCCESS'
export const RESET_REQUESTS_SUMMARY_DATA = 'RESET_REQUESTS_SUMMARY_DATA'