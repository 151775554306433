import * as actionTypes from './actionTypes'

 // action to set requests data
 export const setRequestsData = (objectVal) => {
     return {
         type: actionTypes.SET_REQUESTS_DATA,
         payload: objectVal,
     };
 };
 
 // action to set requests loader
 export const showRequestsLoader = (booleanVal) => {
     return {
         type: actionTypes.SET_REQUESTS_LOADER,
         payload: booleanVal,
     };
 };
 
 // action to set the requests fetch success state
 export const setRequestsFetchSuccess = (booleanVal) => {
     return {
         type: actionTypes.SET_REQUESTS_FETCH_SUCCESS,
         payload: booleanVal,
     };
 };
 
 /* -----------------------------------------------------------------------------
      ACTIONS CREATORS
  ----------------------------------------------------------------------------- */
 
 // call the action to fetch requests data
 export const getRequestsData = () => {
     return {
         type: actionTypes.FETCH_REQUESTS_DATA,
     };
 };
 
 
 //reset fetched request data
 
 export const resetRequestsData = () => {
    
     return {
         type: actionTypes.RESET_REQUESTS_DATA,
         
     };
 };

/* -----------------------------------------------------------------------------
    REQUESTS SUMMARY ACTIONS CREATORS
----------------------------------------------------------------------------- */

export const fetchRequestsSummary = () => {
    return {
        type: actionTypes.FETCH_REQUESTS_SUMMARY_DATA
    }
}

export const setRequestsSummaryData = (objectVal) => {
    return {
        type: actionTypes.SET_REQUESTS_SUMMARY_DATA,
        payload: objectVal,
    };
};

export const setRequestsSummaryDataSuccess = (booleanVal) => {
    return {
        type: actionTypes.SET_REQUESTS_SUMMARY_DATA_SUCCESS,
        payload: booleanVal,
    };
};