 import { combineReducers } from 'redux'
 import {dashboard} from '__pages/Dashboard/__redux/reducers'
 import {profileReducer} from '__pages/Account/__redux/reducers'
 import {requestsReducer} from '__pages/Requests/__redux/reducers'
 import { fundState } from '__pages/Fund/__redux/reducers'
 import { documentsState } from '__pages/Documents/__redux/reducers'
 import { billingState } from '__pages/Billing/__redux/reducers'
 import {rightMenuState} from '__layout/RightMenu/__redux/reducers'
 
 // add all reducers here to match the initState
 export default combineReducers({
     dashboard,
     profileReducer,
     requestsReducer,
     fundState,
     billingState,
     documentsState,
     rightMenuState
 });
  