import message from 'antd/es/message';
import cx from 'classnames';
import Footer from 'components/Footer';
//layouts
import PageNotFound from 'components/PageNotFound';
import React from 'react';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from 'routes';
import LeftMenu from '__layout/LeftMenu/';
import Overlay from '__layout/Overlay/';
import RightMenu from '__layout/RightMenu/';
import TopBar from '__layout/TopBar';
import Tour from '__layout/Tour/';
import TourDemo from '__layout/TourDemo/';
//redux
import { getProfilesCtr } from '__pages/Account/__redux/actions';
import { getProfileSlr } from '__pages/Account/__redux/selectors';
// style import
import './style.scss';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINTS } from '__utils/constants';
import { getNotificationsData } from '__layout/RightMenu/__redux/actions' 
import {fetchRequestsSummary} from '__pages/Requests/__redux/actions'
import {getRequestsData} from '__pages/Requests/__redux/actions'

const layoutInitValue = {
    openLeftMenu: false,
    openRightMenu: false,
    openCongratsInfo: true, 
    onTour: false,
    onTourHighlight: ''
}

// Global config form toast message
message.config({ top: 0 });

const App = () => {
    const dispatch = useDispatch();
    const GREATER_THAN_MD = useMediaQuery({ query: BREAKPOINTS.GREATER_THAN_MD });
    const LESS_THAN_LG = useMediaQuery({ query: BREAKPOINTS.LESS_THAN_LG });

    const { data: profileData } = useSelector(getProfileSlr);
    const { email, is_new } = profileData;
    const [isDemo, setIsDemo] = React.useState(false);
    const [layoutProps , setLayoutProps] = React.useState(layoutInitValue)
    const { openLeftMenu, openRightMenu} = layoutProps
    
    React.useEffect(() => {
        dispatch(getProfilesCtr())
        dispatch(getNotificationsData());
        dispatch(fetchRequestsSummary())
        dispatch(getRequestsData())
    }, [dispatch]);

    // member-area classes based on the truthy value 'openRightMenu'
    const dynClass = cx('member-area', { 'right-menu-open': openRightMenu });

    React.useEffect(() => {
        if (email === 'demo+1865@selfmade.com.au' && !isDemo) {
            setIsDemo(true);
            setLayoutProps({...layoutProps, openRightMenu: true }) 
        }

        if (GREATER_THAN_MD && is_new) {
            setLayoutProps({...layoutProps, openRightMenu: true }) 
        }
    }, [GREATER_THAN_MD, email, is_new,layoutProps,isDemo]);

    const onBurgerMenuClick = () =>{
        setLayoutProps({...layoutProps, openLeftMenu: !openLeftMenu})
    }

    const onCloseClickOverLay = () =>{
        setLayoutProps({...layoutProps, openLeftMenu: false , openRightMenu:false})
    }

    const onClickLeftMenu = () => {
        setLayoutProps({...layoutProps, openLeftMenu: false})
    }

    const onOpenCloseRightMenu = (val) => {
        setLayoutProps({...layoutProps, openRightMenu: val})
    }
 
    const onCloseCongratsInfoBox = () => {
        setLayoutProps({...layoutProps, openCongratsInfo: false})
    }

    const onSetTour = (val) => {
        setLayoutProps({...layoutProps, onTour: val });
    }

    const onSetTourHighLight = (val) => {
        setLayoutProps({...layoutProps, onTourHighlight: val });
    }

    return (
        <Router>
            <div className={dynClass}>
                {/* top bar component */}
                <TopBar onBurgerMenuClick={onBurgerMenuClick}/>

                {/* body element that contains the overlay, menus & footer */}
                <div className="body-container">
                    {/* overlay component (only show on tablet and mobile)*/}
                    {LESS_THAN_LG && <Overlay onCloseClickOverLay={onCloseClickOverLay} layoutProps={layoutProps}/>}

                    {/* routing happens only in the main area */}
                    <div className="main">
                        <React.Suspense fallback={null}>
                            <Switch>
                                {routes.map((route) => {
                                    const { component: Component ,...rest } = route;
                                    return <Route {...rest} ><Component layoutProps={layoutProps}/></Route>
                                })}
                                <Route>
                                    <PageNotFound />
                                </Route>
                            </Switch>
                        </React.Suspense>
                    </div>

                    {/* left menu component */}
                    <LeftMenu onClickLeftMenu={onClickLeftMenu} layoutProps={layoutProps}/>

                    {/* right menu component */}
                    <RightMenu layoutProps={layoutProps} onOpenCloseRightMenu={onOpenCloseRightMenu} onCloseCongratsInfoBox={onCloseCongratsInfoBox}/>

                    {/* footer component (only show on tablet)*/}
                    {LESS_THAN_LG && <Footer />}
                </div>

                {/* Tour component (only in desktop) */}
                {GREATER_THAN_MD && <Tour onOpenCloseRightMenu={onOpenCloseRightMenu} onSetTour={onSetTour} onSetTourHighLight={onSetTourHighLight} />}

                {/* Tour component (only in desktop) for DEMO account */}
                {GREATER_THAN_MD && isDemo && <TourDemo onOpenCloseRightMenu={onOpenCloseRightMenu} onSetTour={onSetTour} onSetTourHighLight={onSetTourHighLight}/>}
            </div>
        </Router>
    );
};

export default App;
