import React from 'react'
import {endpoints} from '__utils/constants'

// component import
import AppButton from 'components/AppButton/'
import AppLink from 'components/AppLink'

// style import
import './style.scss'


const Footer = () => {
    const signOutClick = () => {
        console.log('Sign Out');
    };
    return (
        <div className="footer">
            {/* sign out button */}
            <div className="footer__sign-out-button">
                <AppLink isExternal={true} route={endpoints.websiteSignOutURL}>
                    <AppButton buttonText="SIGN OUT" onClick={signOutClick} size="big" color="blue" />
                </AppLink>
            </div>
            {/* text container */}
            <div className="footer__text_container">
                <p className="footer__text">© Copyright Selfmade 2018</p>
                <AppLink
                    className="footer__links footer__terms"
                    route={endpoints.websiteTermsURL}
                    isExternal={true}
                    openInNewTab={true}
                >
                    Terms & conditions
                </AppLink>
                <AppLink
                    className="footer__links footer__privacy"
                    route={endpoints.websitePrivacyPolicyURL}
                    isExternal={true}
                    openInNewTab={true}
                >
                    Privacy policy
                </AppLink>
            </div>
        </div>
    );
};

export default Footer;
