import { takeEvery, call, put ,select} from "redux-saga/effects";
import moment from "moment";
import {transactionSlr } from './selectors'

import * as actionTypes from "./actionTypes";
import * as api from '../__api'
import { request, isEmpty, substitute } from "__utils/utilities";

// import action creators
import * as actions from "./actions";

export function* getInvestmentSaga() {
    yield takeEvery(actionTypes.FETCH_INVESTMENT_DATA, getInvestment);
}

// worker saga for fetching the investmentn data
function* getInvestment() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors', // NOTE: make sure Mulesoft endpoint allows CORS !!!!!!
        cache: 'default',
    };

    // set the endpoint
    const todayDate = moment().format('YYYY-MM-DD'),
        requestURL = substitute(api.investmentFetchURL, { todayDate: todayDate });
    try {
        // show the loader
        yield put(actions.showInvestmentLoader(true));

        // get the response promise object
        const response = yield call(request, requestURL, headers);
        //added additional check if there is data and value is 0 show no data avaliable
        if (!isEmpty(response.data) && response.data.data.portfolioAssetPositions[0].overallValue > 0) {
            // get the array of transaction objects
            const bankAccountDetails = response.data.data.portfolioAssetPositions;

            // set the transaction list
            yield put(actions.setInvestmentData(bankAccountDetails[0].assetClassDetailList));

            // set the success flag to true
            yield put(actions.setInvestmentFetchSuccess(true));
        }
    } catch (error) {
        // set the success flag to false
        yield put(actions.setInvestmentFetchSuccess(false));
    } finally {
        // hide the loader regardless
        yield put(actions.showInvestmentLoader(false));
    }
}

//PORTFOLIO
export function* getPortfolioSaga() {
    yield takeEvery(actionTypes.FETCH_PORTFOLIO_DATA, getPortfolio);
}

// worker saga for fetching the portfolio data
function* getPortfolio() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors', // NOTE: make sure Mulesoft endpoint allows CORS !!!!!!
        cache: 'default',
    };

    // set the endpoint
    const startDate = moment().subtract(6, 'months').format('YYYY-MM-DD'),
        endDate = moment().format('YYYY-MM-DD'),
        requestURL = substitute(api.portfolioFetchURL, { startDate: startDate, endDate: endDate });

    try {
        // show the loader
        yield put(actions.showPortfolioLoader(true));

        // get the response promise object
        const response = yield call(request, requestURL, headers);

        if (!isEmpty(response.data) && response.data.currentValue > 0) {
            // get the array of transaction objects
            const bankAccountDetails = response.data;

            // set the postfolio bar data
            let fundHistory = bankAccountDetails.history;

            // If cron hasn't run to generate bar data but fun history has value use it for today's value
            // Or history not be return but today's value is available
            if (!fundHistory || !fundHistory.length)
                fundHistory = [{ dateCreated: endDate, fundValue: bankAccountDetails.currentValue }];

            yield put(actions.setPortfolioData(fundHistory));

            // set fund value
            yield put(actions.setPortfolioFundValue(parseFloat(bankAccountDetails.currentValue, 10)));

            // set the success flag to true
            yield put(actions.setPortfolioFetchSuccess(true));
        }
    } catch (error) {
        // set the success flag to false
        yield put(actions.setPortfolioFetchSuccess(false));
    } finally {
        // hide the loader regardless
        yield put(actions.showPortfolioLoader(false));
    }
}

//TRANSACTION
export function* getTransactionSaga() {
    yield takeEvery(actionTypes.FETCH_TRANSACTION_DATA, getTransaction);
}

// worker saga for fetching the transaction data
function* getTransaction() {
    // get the store content
    const {startDate:_startDate,endDate :_endDate} = yield select(transactionSlr);
    const startDate = moment(_startDate).format('YYYY-MM-DD');  ;
    const endDate =  moment(_endDate).format('YYYY-MM-DD');  ;

    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors', // NOTE: make sure Mulesoft endpoint allows CORS !!!!!!
        cache: 'default',
    };

    // set the endpoint
    const requestURL = substitute(api.transactionFetchURL, { startDate, endDate });

    try {
        // show the loader
        yield put(actions.showTransactionLoader(true));

        // get the response promise object
        const response = yield call(request, requestURL, headers);
        if (!isEmpty(response.data)) {
            // get the array of transaction objects
            const bankAccountDetails = response.data.data;

            // set the closing balance
            yield put(actions.setTransactionClosingBalance(parseFloat(bankAccountDetails.closingBalance, 10)));

            // set the transaction list
            yield put(actions.setTransactionData(bankAccountDetails.transactions));

            // set the success flag to true
            yield put(actions.setTransactionFetchSuccess(true));
        }
    } catch (error) {
        // set the success flag to false
        yield put(actions.setTransactionFetchSuccess(false));
    } finally {
        // hide the loader regardless
        yield put(actions.showTransactionLoader(false));
    }
}

/* -----------------------------------------------------------------------------
    WATCHER & WORKER SAGA TO RESET THE DATE RANGE
----------------------------------------------------------------------------- */

// watcher saga for resetting the date range
export function* clearDateRangeSaga() {
    yield takeEvery(actionTypes.CLEAR_DATE_RANGE, clearDateRange);
}

// worker saga for resetting the date range
function* clearDateRange() {
    // set the start date of 3 months from NOW
    yield put(actions.setTransactionStartDate(moment().subtract(3, 'months')));

    // set the end date of NOW
    yield put(actions.setTransactionEndDate(moment()));
}

//NEWS
// watcher saga for fetching the news data
export function* getNewsSaga() {
    yield takeEvery(actionTypes.FETCH_NEWS_DATA, getNews);
}

// worker saga for fetching the fund data
function* getNews() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    // set the endpoint
    const requestURL = api.newsFetchURL;

    try {
        // show the loader
        yield put(actions.showNewsLoader(true));

        // get the response promise object
        const response = yield call(request, requestURL, headers);

        if (!isEmpty(response.data)) {
            // set the news data
            yield put(actions.setNewsData(response.data));
        }
    } catch (error) {
    } finally {
        // hide the loader regardless
        yield put(actions.showNewsLoader(false));
    }
}
