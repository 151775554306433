import * as actionTypes from './actionTypes'

//NOTIFICATION
// action to hide/show the loader for the notifications
export const showNotificationsLoader = (booleanVal) => {
    return {
        type: actionTypes.SET_NOTIFICATIONS_LOADER,
        payload: booleanVal,
    };
};

// action to set the notifications data
export const setNotificationsData = (objectVal) => {
    return {
        type: actionTypes.SET_NOTIFICATIONS_DATA,
        payload: objectVal,
    };
};

export const removeNotificationsPendingAction = (pendingActionId) => {
    return {
        type: actionTypes.REMOVE_NOTIFICATIONS_PENDING_ACTION,
        payload: pendingActionId,
    };
};

/* -----------------------------------------------------------------------------
    ACTIONS CREATORS
----------------------------------------------------------------------------- */
// call the action to fetch the data
// NOTE: this will be picked up by the notifications saga (sagas/notifications.js)
export const getNotificationsData = () => {
    return {
        type: actionTypes.FETCH_NOTIFICATIONS_DATA,
    };
};

// call the action to dismiss a notification
// NOTE: this will be picked up by the notifications saga (sagas/notifications.js)
export const dismissNotificationRequest = (id) => {
    return {
        type: actionTypes.DISMISS_NOTIFICATION,
        payload: id,
    };
};

//REFERRAL
// action to hide/show the loader for the referral process
export const showReferralLoader = (data) => {
    return {
        type: actionTypes.SET_REFERRAL_LOADER,
        payload: data,
    };
};

// action to hide/show the loader for the referral process
export const setReferralResponse = (data) => {
    return {
        type: actionTypes.SET_REFERRAL_RESPONSE,
        payload: data,
    };
};

/* -----------------------------------------------------------------------------
    ACTIONS CREATORS
----------------------------------------------------------------------------- */

// call the action to send a referral email
// NOTE: this will be picked up by the referral saga (sagas/referral.js)
export const sendReferralRequest = (data) => {
    return {
        type: actionTypes.SEND_REFERRAL,
        payload: data,
    };
};
