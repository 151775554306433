import moment from 'moment';
import { combineReducers } from 'redux';
import * as actionTypes from './actionTypes';

//INVESTMENT REDUCERS
const investmentInitState = {
    loading: false,
    fetchSuccess: true,
    data: [],
};

export const investmentStateReducer = (state = investmentInitState, action) => {
    switch (action.type) {
        // in case of `Loading` change
        case actionTypes.SET_INVESTMENT_LOADER:
            return {
                ...state,
                loading: action.payload,
            };

        // in case of `Data` change
        case actionTypes.SET_INVESTMENT_DATA:
            return {
                ...state,
                data: action.payload,
            };

        // in case of `iInvestmentSuccess` change
        case actionTypes.SET_INVESTMENT_FETCH_SUCCESS:
            return {
                ...state,
                fetchSuccess: action.payload,
            };

        default:
            return state;
    }
};

//PORTFOLIO
const portfolioInitState = {
    pLoading: false,
    pFetchSuccess: true,
    pData: [],
    pFundValue: 0,
};

export const portfolioStateReducer = (state = portfolioInitState, action) => {
    switch (action.type) {
        // in case of `pLoading` change
        case actionTypes.SET_PORTFOLIO_LOADER:
            return {
                ...state,
                pLoading: action.payload,
            };

        // in case of `pData` change
        case actionTypes.SET_PORTFOLIO_DATA:
            return {
                ...state,
                pData: action.payload,
            };

        // in case of `iInvestmentSuccess` change
        case actionTypes.SET_PORTFOLIO_FETCH_SUCCESS:
            return {
                ...state,
                pFetchSuccess: action.payload,
            };

        // in case of `tClosingBalance` change
        case actionTypes.SET_PORTFOLIO_FUND_VALUE:
            return {
                ...state,
                pFundValue: action.payload,
            };

        default:
            return state;
    }
};

//TRANSACTION REDUCERS
const transactionInitState = {
    loading: false,
    startDate: moment().subtract(3, 'months'), // date of f months before now
    endDate: moment(), // date of now
    fetchSuccess: true,
    data: [],
    closingBalance: 0,
};

export const transactionStateReducer = (state = transactionInitState, action) => {
    switch (action.type) {
        // in case of `Loading` change
        case actionTypes.SET_TRANSACTION_LOADER:
            return {
                ...state,
                loading: action.payload,
            };

        // in case of `StartDate` change
        case actionTypes.SET_TRANSACTION_START_DATE:
            return {
                ...state,
                startDate: action.payload,
            };

        // in case of `EndDate` change
        case actionTypes.SET_TRANSACTION_END_DATE:
            return {
                ...state,
                endDate: action.payload,
            };

        // in case of `FetchSuccess` change
        case actionTypes.SET_TRANSACTION_FETCH_SUCCESS:
            return {
                ...state,
                fetchSuccess: action.payload,
            };

        // in case of `Data` change
        case actionTypes.SET_TRANSACTION_DATA:
            return {
                ...state,
                data: action.payload,
            };

        // in case of `ClosingBalance` change
        case actionTypes.SET_TRANSACTION_CLOSING_BALANCE:
            return {
                ...state,
                closingBalance: action.payload,
            };

        // reset data
        case actionTypes.SET_TRANSACTION_CLOSING_BALANCE_RESET:
            return {
                ...state,
                ...transactionInitState
            };

        default:
            return state;
    }
};

const newsInitState = {
    nLoading: false,
    nData: [],
};

export const newsStateReducer = (state = newsInitState, action) => {
    switch (action.type) {
        // in case of `nLoading` change
        case actionTypes.SET_NEWS_LOADER:
            return {
                ...state,
                nLoading: action.payload,
            };

        // in case of `nData` change
        case actionTypes.SET_NEWS_DATA:
            return {
                ...state,
                nData: action.payload,
            };

        default:
            return state;
    }
};

export const dashboard = combineReducers({
    investmentState: investmentStateReducer,
    portfolioState: portfolioStateReducer,
    transactionState: transactionStateReducer,
    newsState: newsStateReducer,
});
