/**
* this file contains the array for the left menu component
*
* Created by Tom on 28/5/17
*/

import {endpoints} from '__utils/constants'

// menu icon import
import DashIcon from 'assets/svg/dash.svg'
import MyFundIcon from 'assets/svg/wallet.svg'
import TradingIcon from 'assets/svg/trading.svg'
import DocumentIcon from 'assets/svg/documents.svg'
import BillingIcon from 'assets/svg/billing.svg'


 
const menuItems = [
    {
        menuIcon: DashIcon,
        menuText: 'DASHBOARD',
        isExternal: false,
        openInNewTab: false,
        route: '/',
        tourId: 'dashboard_target'
    },
    {
        menuIcon: '',
        menuText: 'REQUESTS',
        isExternal: false,
        openInNewTab: false,
        route: '/requests',
        tourId: 'requests_target',
    },
    {
        menuIcon: MyFundIcon,
        menuText: 'MY FUND',
        isExternal: false,
        openInNewTab: false,
        route: '/myfund',
        tourId: 'myfund_target'
    },
    {
        menuIcon: TradingIcon,
        menuText: 'TRADING',
        isExternal: true,
        openInNewTab: true,
        route: endpoints.tradingExternalURL,
        tourId: 'trading_target'
    },
    {
        menuIcon: DocumentIcon,
        menuText: 'DOCUMENTS',
        isExternal: false,
        openInNewTab: false,
        route: '/documents',
        tourId: 'documents_target'
    },
    {
        menuIcon: BillingIcon,
        menuText: 'BILLING',
        isExternal: false,
        openInNewTab: false,
        route: '/billing',
        tourId: 'billing_target',
    }
    
]

export default menuItems
