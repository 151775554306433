import React from 'react'
import PropTypes from 'prop-types'
// Joyride imports
import Joyride from "react-joyride"
import { ACTIONS, EVENTS } from 'react-joyride/es'
import {tourState} from './__utils/constants'
import { useLocation } from 'react-router-dom';

import './style.scss'
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINTS } from '__utils/constants';
//redux
import {useDispatch,useSelector} from 'react-redux'
import {editProfilesCtr} from '__pages/Account/__redux/actions'
import {getProfileSlr} from '__pages/Account/__redux/selectors'
import {usePrevious} from '__utils/utilities'


const Tour = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [_tourState, setTourState] = React.useState(tourState);
    const { pathname, search } = location;
    const {onOpenCloseRightMenu ,onSetTour, onSetTourHighLight} = props
    //selector
    const GREATER_THAN_LG = useMediaQuery({ query: BREAKPOINTS.GREATER_THAN_LG });
    const { data: profileData } = useSelector(getProfileSlr);
    const prevIsNew = usePrevious(profileData.is_new);
 
    React.useEffect(() => {
        if (pathname === '/' && search === '?runTour' && GREATER_THAN_LG) {
            setTourState({ ..._tourState, run: true });
        }
    }, [_tourState, GREATER_THAN_LG, pathname, search]);

    React.useEffect(() => {
        // Watch for profile data, and if user is new to the site, flag the tour to start
        if (profileData['is_new'] !== prevIsNew && profileData['is_new'] === true && pathname === '/' && GREATER_THAN_LG) {
            // Only want to display the tour once
            const newProfiledata = { ...profileData, is_new: false };
            // Push the change
            dispatch(editProfilesCtr(newProfiledata));

            // Run the tour
            setTourState({ ..._tourState, run: true });
        }
    }, [GREATER_THAN_LG, _tourState, dispatch, pathname, profileData, prevIsNew]);
    const handleJoyrideCallback = async (data) => {
        const { action, index, type } = data;

        if (type === EVENTS.TOUR_START && _tourState.run) {
            setTimeout(() => {
                onSetTour(true)
            }, 850);
        } else if (type === EVENTS.TOUR_END && _tourState.run) {
            // End the tour
            setTourState({ ..._tourState, run: false });
            onSetTour(false)
        } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 2) {
            setTourState({ ..._tourState, run: false, loading: true });
            setTimeout(() => {
                setTourState({
                    ..._tourState,
                    loading: false,
                    run: true,
                    stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
                });
                onOpenCloseRightMenu(false)
            }, 850);
        } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV && index === 3) {
            setTourState({ ..._tourState, run: false, loading: true });
            onOpenCloseRightMenu(true)

            setTimeout(() => {
                setTourState({
                    ..._tourState,
                    loading: false,
                    run: true,
                    stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
                });
            }, 850);
        } else if (type === EVENTS.STEP_AFTER && index === 9) {
            // End the tour
            setTourState({ ..._tourState, run: false });
            setTimeout(() => {
                onSetTour(false)
            }, 850);
           
            // Remove routing to documents page as these are no longer autogenerated.
            //goToRoute('/documents')
        } else if (type === EVENTS.STEP_AFTER) {
            onSetTour(true)
            setTourState({ ..._tourState, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
        } else if (type === EVENTS.TOOLTIP_CLOSE) {
            setTourState({ ..._tourState, stepIndex: index + 1 });
        }

        if (index === 3) {
            onSetTourHighLight('DASHBOARD');

        } else if (index === 4) {
            onSetTourHighLight('REQUESTS');
            
        } else if (index === 5) {
            onSetTourHighLight('MY FUND');
            
           
        } else if (index === 6) {
            onSetTourHighLight('TRADING');
           
            
        } else if (index === 7) {
            onSetTourHighLight('DOCUMENTS');
           
        } else if (index === 8) {
            onSetTourHighLight('BILLING');
            
        } else {
            onSetTourHighLight('');
        }
    };

    const { ...joyrideProps } = _tourState;
    const tourProps = {
        ...joyrideProps,
    };

    return <Joyride scrollToFirstStep showSkipButton {...tourProps} callback={handleJoyrideCallback} />;
};

// define the prop types
Tour.propTypes = {
    onSetTourHighLight: PropTypes.func,
    onOpenCloseRightMenu: PropTypes.func,
    onSetTour:PropTypes.func,
};


export default Tour;
