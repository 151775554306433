 // notificationsReducer
 export const SET_NOTIFICATIONS_LOADER= 'SET_NOTIFICATIONS_LOADER';
 export const FETCH_NOTIFICATIONS_DATA= 'FETCH_NOTIFICATIONS_DATA';
 export const SET_NOTIFICATIONS_DATA= 'SET_NOTIFICATIONS_DATA';
 export const DISMISS_NOTIFICATION= 'DISMISS_NOTIFICATION';
 export const REMOVE_NOTIFICATIONS_PENDING_ACTION= 'REMOVE_NOTIFICATIONS_PENDING_ACTION';



 // referralReducers
 export const SEND_REFERRAL= 'SEND_REFERRAL';
 export const SET_REFERRAL_LOADER= 'SET_REFERRAL_LOADER';
 export const SET_REFERRAL_RESPONSE= 'SET_REFERRAL_RESPONSE';