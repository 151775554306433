import * as actionTypes from './actionTypes'

// action to show/hide loader
export const showFundLoader = booleanVal => {
    return {
        type: actionTypes.SET_FUND_LOADER,
        payload: booleanVal
    }
}

// action to set fund info data
// payload is the complete fund info data
export const setFundInfoData = objectVal => {
    return {
        type: actionTypes.SET_FUND_INFO_DATA,
        payload: objectVal
    }
}

// action to set basic fund info data
// payload is the complete fund info data
export const setBasicFundInfoData = objectVal => {
    return {
        type: actionTypes.SET_BASIC_FUND_INFO_DATA,
        payload: objectVal
    }
}


// action to set fund memebers and trustees data
// payload is the complete fund info data
export const setMembersTrusteesInfoData = objectVal => {
    return {
        type: actionTypes.SET_MEMBERS_TRUSTEES_DATA,
        payload: objectVal
    }
}


// action to set company details
// payload is the complete fund info data
export const setCompanyDetailsData = objectVal => {
    return {
        type: actionTypes.SET_COMPANY_DETAILS_DATA,
        payload: objectVal
    }
}


/* -----------------------------------------------------------------------------
    ACTIONS CREATORS
----------------------------------------------------------------------------- */
// call the action to fetch the data
// NOTE: this will be picked up by the profile saga (sagas/profile.js)
export const getFundData = () => {
    return {
        type: actionTypes.FETCH_FUND_DATA,
    }
}

// Fetch basic fund data
export const getBasicFundData = () => {
    return {
        type: actionTypes.FETCH_BASIC_FUND_DATA,
    }
}
