/**
 * these functions save and retrieve REDUX store data from and to localStorage
 *
 *
 * Created by Tom on 11/8/17
 */

// load the REDUX store data from localStorage
export const loadState = () => {
    // NOTE: calls to localStorage can fail due to privacy settings of the browser
    // Hence, we need to wrap the call into a try/catch block
    try {

        const serializedState = localStorage.getItem('appState')
        // if key doesn't exist return undefined
        if(serializedState === null) {
            // createStore() can only accept undefined, [], {} & functions
            return {}
        }
        // finally the serialized state
        return JSON.parse(localStorage.getItem('appState'))

    } catch (err) {
        // createStore() can only accept undefined, [], {} & functions
        return {}
    }
}

// save the REDUX store data to localStorage
export const saveState = stateObject => {
    try {

        localStorage.setItem('appState', JSON.stringify(stateObject))

    } catch (err) {
        console.error('Failed to save to localStorage', err)
    }
}
