import { call, put, takeEvery } from 'redux-saga/effects';
import { request, substitute } from '__utils/utilities';
import * as api from '../__api';
// redux
import * as actions from './actions';
import * as actionTypes from './actionTypes';

// watcher saga for fetching the billing data
export function* getRequestsDataSaga() {
    yield takeEvery(actionTypes.FETCH_REQUESTS_DATA, getRequestsData);
}

// worker saga for fetching biling data
function* getRequestsData() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    // set the endpoint
    const requestURL = substitute(api.requestsFetchURL, {});

    try {
        // show the loader
        yield put(actions.showRequestsLoader(true));

        // get the response promise object
        const response = yield call(request, requestURL, headers);
        const { status, data } = response;

        if (status === 200) {
            const requestsData = [...data];

            yield put(actions.setRequestsData(requestsData));

            yield put(actions.setRequestsFetchSuccess(true));
        } else if (status === 404) {
            window.location.assign('/pageNotFound');
        }
    } catch (error) {
        // set the success flag to false
        yield put(actions.setRequestsFetchSuccess(false));
    } finally {
        // hide the loader regardless
        yield put(actions.showRequestsLoader(false));
    }
}

export function* fetchRequestsSummarySaga() {
    yield takeEvery(actionTypes.FETCH_REQUESTS_SUMMARY_DATA, fetchRequestsSummary);
}

function* fetchRequestsSummary() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    // set the endpoint
    const requestURL = substitute(api.fetchRequestsSummaryUrl, {});

    try {
        // get the response promise object
        const response = yield call(request, requestURL, headers);
        const { status, data } = response;

        if (status === 200) {
            const requestsData = [...data];

            yield put(actions.setRequestsSummaryData(requestsData));

            yield put(actions.setRequestsSummaryDataSuccess(true));
        } else if (status === 404) {
            window.location.assign('/pageNotFound');
        }
    } catch (error) {
        // set the success flag to false
        yield put(actions.setRequestsSummaryDataSuccess(false));
    }
}