import React from 'react'
import PropTypes from 'prop-types'
import {isEmpty} from '__utils/utilities'

// component import
import AppLink from 'components/AppLink'
import AppButton from 'components/AppButton'

// style import
import './style.scss'


const PendingActionItem = (props) => {
    const { title, link1, link2, show_dismiss, onRemove } = props;

    const renderLink = (link) => {
        if (isEmpty(link) || isEmpty(link.url)) return;

        const { url, text } = link;

        return (
            <AppLink route={url} isExternal={true} className="PendingActionItem__link">
                {text || 'More info'}
            </AppLink>
        );
    };

    return (
        <div className="PendingActionItem">
            <div className="PendingActionItem__text-wrap">
                <div className="PendingActionItem__title">{title}</div>
                <div>
                    {renderLink(link1)}
                    {renderLink(link2)}
                </div>
            </div>
            {show_dismiss && (
                <AppButton
                    className="PendingActionItem__button"
                    size="small"
                    color="blue"
                    onClick={onRemove}
                    buttonText="Done"
                />
            )}
        </div>
    );
};

// define the prop types
PendingActionItem.propTypes = {
    title: PropTypes.string,
    link1: PropTypes.object,
    link2: PropTypes.object,
    show_dismiss: PropTypes.bool,
    onRemove: PropTypes.func,
};

export default PendingActionItem;
