import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Space from 'antd/es/space';
import { LoadingOutlined } from '@ant-design/icons';
// style import
import './style.scss'

const AppButton = ({ buttonText, size, color, onClick, type, className, isDisabled, isloading = false }) => {
    // class expression
    let dynClass = cx('app-button', 'app-button--' + size, 'app-button--' + color, className);

    return (
        <button className={dynClass} onClick={onClick} type={type} disabled={isDisabled} >
            <Space>
                {' '}
                {isloading && <LoadingOutlined style={{ fontSize: 14 }} spin />}
                {buttonText}{' '}
            </Space>
        </button>
    );
};

// define the prop types
AppButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
};

export default AppButton
