import * as actionTypes from './actionTypes';

// action to set doc sign link
export const setDocSignStatus = (objectVal) => {
    return {
        type: actionTypes.SET_DOC_SIGN_STATUS,
        payload: objectVal,
    };
};

// action to set documents data
export const setDocumentData = (objectVal) => {
    return {
        type: actionTypes.SET_DOCUMENTS_DATA,
        payload: objectVal,
    };
};

// action to set documents loader
export const showDocumentsLoader = (booleanVal) => {
    return {
        type: actionTypes.SET_DOCUMENTS_LOADER,
        payload: booleanVal,
    };
};

// action to set documents sign loader
export const showDocSignStatusLoader = (booleanVal) => {
    return {
        type: actionTypes.SET_DOC_SIGN_STATUS_LOADER,
        payload: booleanVal,
    };
};

// action to set the documents fetch success state
export const setDocumentsFetchSuccess = (booleanVal) => {
    return {
        type: actionTypes.SET_DOCUMENTS_FETCH_SUCCESS,
        payload: booleanVal,
    };
};

// action to set the documents sign details
export const setDocSignDetails = (signerData) => {
    return {
        type: actionTypes.SET_DOC_SIGN_DETAILS,
        payload: signerData,
    };
};
// action to set the member documents details
export const setMemberDocDetails = (memberDocData) => {
    return {
        type: actionTypes.SET_MEMBER_DOC_DETAILS,
        payload: memberDocData,
    };
};
// action to set the member document categories
export const setMemberDocCategories = (memberDocCats) => {
    return {
        type: actionTypes.SET_MEMBER_DOC_CATEGORIES,
        payload: memberDocCats,
    };
};
// action to set the member google drive folder lookup
export const setMemberDocFolderLookup = (memberDocFolderLookop) => {
    return {
        type: actionTypes.SET_MEMBER_DOC_FOLDER_LOOKUP,
        payload: memberDocFolderLookop,
    };
};

/* -----------------------------------------------------------------------------
    ACTIONS CREATORS
----------------------------------------------------------------------------- */
// call the action to fetch doc sign link
export const getDocSignStatus = () => {
    return {
        type: actionTypes.FETCH_DOC_SIGN_STATUS,
    };
};

// call the action to fetch documents data
export const getDocumentsData = () => {
    return {
        type: actionTypes.FETCH_DOCUMENTS_DATA,
    };
};

// call the action to fetch documents sign details
export const getDocSignDetails = () => {
    return {
        type: actionTypes.FETCH_DOC_SIGN_DETAILS,
    };
};

// call the action to fetch Member documents details
export const getMemberDocumentsData = () => {
    return {
        type: actionTypes.FETCH_MEMBER_DOC_DETAILS,
    };
};

// call the action to fetch Member documents details
export const getMemberDocumentsCategories = () => {
    return {
        type: actionTypes.FETCH_MEMBER_DOC_CATEGORIES,
    };
};
