/**
* this file contains all reusable utility functions
*
* Created by Tom on 24/7/17
*/

import moment from 'moment'
import {endpoints,rootAPIUrl} from './constants'
import React from 'react';

/**
 * Parses body data returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed data, status from the response
 */
const parseBody = response => {
    return new Promise(resolve =>
        response.text().then(text => {
            try {
                const json = JSON.parse(text)
                resolve({
                    status: response.status,
                    ok: response.ok,
                    data: json,
                    json,
                })
            } catch(e) {
                // Unable to parse JSON so it is text
                resolve({
                    status: response.status,
                    ok: response.ok,
                    data: text,
                    text,
                })
            }
        })
    );
}

/**
 * Redirect user to sign-in page when user is unauthorised
 *
 * @param  {object} response A response from a network request
 * @return {object}          [description]
 */
const redirectUnauthorised = (response) => {
    const {status, url} = response
    const regex = new RegExp(`^${rootAPIUrl}`)

    // When our django api return 403 PermissionDenied or Unauthorised
    // we will redirect the user to sign-in page
    if (status === 403 && regex.test(url)) {
        window.location.href = endpoints.websiteSignInURL
    }

    return response
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object}[options]  The options we want to pass to "fetch"
 *
 * @return {Promise}          The request promise
 */
export const request = (url, options) => {
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(redirectUnauthorised)
            .then(parseBody)
            .then(response => {
                if (response.ok) {
                    return resolve({ status: response.status, data: response.data })
                } else {
                    return resolve({ status: response.status, data: response.data })
                }
                // extract the error from the server's json
                //return reject(response.data)
            })
            .catch(error =>
                reject({
                    networkError: error.message
                })
            )
    })
}


// checks if an object is empty
export const isEmpty = (obj) => {
    let res = true;
    for (let prop in obj) {
        if (! obj.hasOwnProperty(prop)) { continue }
        const type = typeof obj[prop]
        switch (type){
            case "object": res = isEmpty(obj[prop])
                break
            case "boolean":
            case "number": res = false // boolean cannot be "empty", also 0 is not empty
                break
            case "string": res = ! obj[prop].length
                break
            case "undefined": res = true
                break
            default: res = !! obj[prop];
                break
        }
        if (!res) {break}
    }
    return res
}

// mock the API call delay
export const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

// check if string has the following conditions:
// - must be between 8 - 16 characters long and
// - must have 2 capital letter or
// 2 numbers or
// 2 special characters e.g. !#.$
export const checkPass = stringData => {
    return /^(?=.*[a-z])(?=(.*[A-Z]){2,}|(.*\d){2,}|(.*[!@#$%^&*(){}[\]:“?<>]){2,}).{8,16}$/g.test(stringData)
}

// converts UTC to local time and formats the time like 01/01/2017
export const formatDateText = (utcDate) => {
    return (utcDate) ? moment.utc(utcDate).local().format('DD/MM/YYYY') : ''
}

/*
* this function converts a number into a international currency format
* i.e. 23456 becomes $23,456
* @param - locale (STRING) - i.e. 'en-US', 'en-GB', 'en-AU'
* @param - style (STRING) - i.e. 'currency'
* @param - currency (STRING) - i.e. 'USD', 'AUD'
* @param - number (STRING or INTEGER) - the number to be formatted
*/
export const intlNumberFormatter = (locale, style, currency, number) => {
    const localeString = (locale) ? locale : 'en-US'
    // create a Intl.NumberFormat object
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
    var formatter = new Intl.NumberFormat(localeString, {
        style: (style) ? style : 'currency',
        currency: (currency) ? currency : 'AUD',
        minimumFractionDigits: 2
    })
    // format and return the currency formatted value
    return formatter.format(number)
}

/**
 * String format util.
 * Use this to substitute string {varaible} with object that has matching
 * key value pairs
 *
 * Example of usage
 * substitute('/api/notifications/{id}/dismiss', {id: '1'})
 * returned: /api/notifications/1/dismiss
 *
 * @param  {str} str e.g /api/notifications/{id}/dismiss
 * @param  {obj} obj An object contain key:value for subsitution
 * @return {str}     substituted str
 */
export const substitute = (str, obj) => {
    return str.replace(/{[^}]+}/g, (match) => {
        const _match = match.replace(/[{}]/g, '')
        return obj[_match] === undefined ? match : obj[_match]
    })
}


/**
 * Strips HTML tags from given string
 * @param s
 * @returns
 */
 export const stripHtmlTags = (s) => {
    return s.replace(/(<([^>]+)>)/gi, '');
};

/**
 * Returns the element height including margins
 * @param element - element
 * @returns {number}
 */
export const outerHeight = (element) => {
    if (!element) return 0;

    const height = element.offsetHeight;
    const style = window.getComputedStyle(element);

    return ['top', 'bottom']
        .map((side) => parseFloat(style[`margin-${side}`]))
        .reduce((total, side) => total + side, height);
};


/**
 * Returns the element height including margins
 * @param element - element
 * @returns {number}
 */
 export const dateIsDue = (dueDate) => {
    const _dueDate = new Date(moment(dueDate).format("YYYY-MM-DD"));
    const dateNow = Date.now();

    if (dateNow >= _dueDate) {
      return true;
    }

    return false;
};


/**
 * Add preload ability to a given component
 * @param factory
 */
 export const lazyWithPreload = (factory) => {
    let LoadedComponent
    let factoryPromise

    const LazyComponent = React.lazy(factory);

    const loadComponent = () =>
        factory().then((module) => {
            LoadedComponent = module.default;
        });

    const Component = ((props) =>
        React.createElement(LoadedComponent || LazyComponent, props));

    Component.preload = () => factoryPromise || loadComponent();

    return Component;
};



/**
 * Hold A previous State
 * @param value
 */
export const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = React.useRef();

    // Store current value in ref
    React.useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
};