import React from 'react'
import PropTypes from 'prop-types'
import AppLink from 'components/AppLink'
// style import
import './style.scss'
import cx from "classnames";
import Badge from 'antd/es/badge'
import { FaTasks } from 'react-icons/fa';
import { AiFillExclamationCircle } from 'react-icons/ai';
//redux
import {  useSelector } from 'react-redux';
import {requestsSlr} from '__pages/Requests/__redux/selectors'
import { dateIsDue } from '__utils/utilities';

const getBadgeColor = (requestsData) => {
    if (!requestsData || !requestsData.length) return ""
    
    for (const val of requestsData) {
        if (dateIsDue(val.dueDate)) return "red"
    }

    return "yellow"
}

const MenuItem = ({
    svgIcon,
    isExternal,
    openInNewTab,
    menuText,
    route,
    onClick,
    pathname,
    tourId,
    onTour,
    onTourHighlight,
}) => {
    const { data: requestsData } = useSelector(requestsSlr)
    const leftIconAltText = `Menu item left icon for ${menuText}`;
    //USE TO DETERMINE IF YOUR IN REQUEST VIEW AND HIGHLIGHT IT
    const _pathame = pathname.includes('request/');
    const badgeColor = getBadgeColor(requestsData)
    const alertClassName = cx('menu-item__menu-alert', {red: badgeColor === "red", yellow: badgeColor === "yellow"})
    
    const dynClass = cx(
        'menu-item',
        {
            'menu-item--active':
                (route === pathname && onTour === false) ||
                (onTour === true && onTourHighlight === menuText) ||
                (menuText === 'REQUESTS' && _pathame),
        },
        tourId
    );

    const menuTextToRender = () => {
        if (menuText === 'REQUESTS' && badgeColor) {
            return (
                <span className="menu-item__menu-text">
                    {menuText}
                    <Badge offset={[0, -20]} count={<AiFillExclamationCircle className={alertClassName} />} />
                </span>
            );
        } else {
            return <span className="menu-item__menu-text">{menuText} </span>;
        }
    };

    return (
        <AppLink
            isExternal={isExternal}
            onClick={onClick}
            route={route}
            openInNewTab={openInNewTab}
            className={dynClass}
        >
            <span className="menu-item__innerwrapper">
                {svgIcon ? (
                    <img src={svgIcon} className="menu-item__left-icon" alt={leftIconAltText} />
                ) : (
                    <FaTasks className="menu-item__left-icon request-icon" alt={leftIconAltText} />
                )}

                {menuTextToRender()}
                {/* Removing the external link indicator from main menu items: isExternal ?
                    <div className="menu-item__right-icon-container">
                        <img src={LinkIcon} className="menu-item__right-icon-container__right-icon-external"
                             alt="Avatar icon"/>
                    </div>
                    :
                    <img src={ArrowIcon} className="menu-item__right-icon" alt="Arrow icon"/>
                */}
            </span>
        </AppLink>
    );
};

// define the prop types
MenuItem.propTypes = {
    svgIcon: PropTypes.string,
    isExternal: PropTypes.bool,
    menuText: PropTypes.string,
    onClick: PropTypes.func,
    pathname: PropTypes.string,
    openInNewTab: PropTypes.bool,
    tourId: PropTypes.string,
    onTour: PropTypes.bool,
    onTourHighlight: PropTypes.string,
    getProfileState: PropTypes.any,
};

export default MenuItem;
