import Cookies from 'js-cookie';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { request, substitute } from '__utils/utilities';
import * as api from '../__api'
// import action creators
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import { requestDetailSlr } from './selectors';

/* -----------------------------------------------------------------------------
 ----------------------------------------------------------------------------- */

// watcher saga for fetching the request view data
export function* getRequestDetailsSaga() {
    yield takeEvery(actionTypes.FETCH_REQUEST_DETAIL_DATA, getRequestDetails);
}

// worker saga for fetching request view data
function* getRequestDetails(action) {
    const { payload } = action;
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    //set the endpoint
    const requestURL = substitute(api.requestDetailFetchURL, payload);

    try {
        // show the loader
        yield put(actions.showRequestDetailLoaderCtr(true));

        // get the response promise object
        const response = yield call(request, requestURL, headers);
        const { status, data } = response;

        if (status === 200) {
            yield put(actions.setRequestDetailCtr({ ...data }));

            yield put(actions.setRequestDetailFetchSuccessCtr(true));
        } else if (status === 404) {
            window.location.assign('/pageNotFound');
        }
    } catch (error) {
        // set the success flag to false
        yield put(actions.setRequestDetailFetchSuccessCtr(false));
    } finally {
        // hide the loader regardless
        yield put(actions.showRequestDetailLoaderCtr(false));
    }
}

// watcher saga for fetching the request to complete
export function* setRequestToCompleteSaga() {
    yield takeEvery(actionTypes.GET_REQUEST_TO_COMPLETE_DATA, setRequestToComplete);
}

// worker saga for fetching request request to complete
function* setRequestToComplete(action) {
    const { payload } = action;

    //set the headers
    const headers = {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
        },
        mode: 'cors',
        cache: 'default',
    };

    //set the endpoint
    const requestURL = substitute(api.requestToCompleteURL, payload);

    try {
        // show the loader
        yield put(actions.showRequestToCompleteLoaderCtr(true));

        // get the response promise object
        const response = yield call(request, requestURL, headers);
        const { status } = response;

        if (status === 204) {
            yield put(actions.setRequestToCompleteSuccessCtr(true));
        }
    } catch (error) {
        // set the success flag to false
        yield put(actions.setRequestToCompleteSuccessCtr(false));
    } finally {
        // hide the loader regardless
        yield put(actions.showRequestToCompleteLoaderCtr(false));
    }
}

// watcher saga for fetching the request to complete
export function* setDocumentToCompleteSaga() {
    yield takeEvery(actionTypes.GET_DOCUMENT_TO_COMPLETE_DATA, setDocumentToComplete);
}

// worker saga for fetching request request to complete
function* setDocumentToComplete(action) {
    const requestDetail = yield select(requestDetailSlr);
    const { payload } = action;
    // set the headers
    const headers = {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
        },
        mode: 'cors',
        cache: 'default',
    };

    //set the endpoint
    const requestURL = substitute(api.documentToCompleteURL, payload);

    try {
        // show the loader
        yield put(actions.showDocumentToCompleteLoaderCtr(true));

        // get the response promise object
        const response = yield call(request, requestURL, headers);
        const { status } = response;

        if (status === 204) {
            const { data } = requestDetail;
            const objIndex = data.documents.findIndex((obj) => obj.id === payload.documentId);

            data.documents[objIndex].isComplete = true;

            yield put(actions.setRequestDetailCtr({ ...data, documents: data.documents }));

            yield put(actions.setDocumentToCompleteSuccessCtr(true));
        }
    } catch (error) {
        // set the success flag to false
        yield put(actions.setDocumentToCompleteSuccessCtr(false));
    } finally {
        // hide the loader regardless
        yield put(actions.showDocumentToCompleteLoaderCtr(false));
    }
}

// watcher saga for fetching the request to complete
export function* removeDocFileSaga() {
    yield takeEvery(actionTypes.GET_REMOVE_DOCUMENT_FILE, removeDocFile);
}
// worker saga for Remove Doc File
function* removeDocFile(action) {
    const { payload } = action;
    // set the headers
    const headers = {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
        },
        mode: 'cors',
        cache: 'default',
    };

    //set the endpoint
    const requestURL = substitute(api.removeDocFileURL, payload);

    try {
        // show the loader
        yield put(actions.showRemoveDocFileLoaderCtr(true));

        // get the response promise object
        const response = yield call(request, requestURL, headers);
        const { status } = response;

        if (status === 200) {
            yield put(actions.setRemoveDocFileSuccessCtr(true));
        }
    } catch (error) {
        // set the success flag to false
        yield put(actions.setRemoveDocFileSuccessCtr(false));
    } finally {
        // hide the loader regardless
        yield put(actions.showRemoveDocFileLoaderCtr(false));
    }
}
