import {rootAPIUrl} from '__utils/constants'

//fetch RequestsDetails
export const requestDetailFetchURL= rootAPIUrl + "/requests/{requestId}/";

//Request To COmplete 
export const requestToCompleteURL= rootAPIUrl + "/requests/complete/{requestId}/";

//Document To Complete 
export const documentToCompleteURL= rootAPIUrl + "/requests/complete/{requestId}/document/{documentId}/";

//Upload Member Documents
export const documentUploadURL= rootAPIUrl + "/member-documents/upload_request_document/";

//Remove Member Documents
export const removeDocFileURL= rootAPIUrl + "/member-documents/delete_document/{fileKey}/";