import * as actionTypes from './actionTypes'

/* -----------------------------------------------------------------------------
    ACTIONS CREATORS PROFILE
----------------------------------------------------------------------------- */

//GET PROFILE DATA
export const getProfilesCtr = () => {
    return {
        type: actionTypes.GET_PROFILE,
    };
};

export const getProfilesResetCtr = () => {
    return {
        type: actionTypes.GET_PROFILE_RESET,
    };
};

//EDIT PROFILE DATA

export const editProfilesCtr = (data) => {
    return {
        type: actionTypes.EDIT_PROFILE,
        payload: data
    };
};

export const editProfilesResetCtr = () => {
    return {
        type: actionTypes.EDIT_PROFILE_RESET,
    };
};
