import { takeEvery, call, put ,takeLatest,delay} from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import * as api from '../__api'
import { request, isEmpty } from "__utils/utilities";
// import action creators
import * as actions from "./actions";

/* -----------------------------------------------------------------------------
    WATCHER & WORKER SAGA TO FETCH THE DOCUMENTS DATA
----------------------------------------------------------------------------- */

// watcher saga for fetching the doc sign link
export function* getDocSignStatusSaga() {
    yield takeLatest(actionTypes.FETCH_DOC_SIGN_STATUS, getDocSignStatus);
}

// worker saga for fetching the the doc sign link
function* getDocSignStatus() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    // set the endpoint
    const requestURL = api.documentStatusURL;
    let data = false;
    let attemptCounter = 0;
    let maxAttempts = 360;

    while (!data && attemptCounter < maxAttempts) {
        // if docusign is not ready try again

        try {
            // show the loader

            if (!attemptCounter) {
                yield put(actions.showDocSignStatusLoader(true));
            }
            // get the response promise object
            const response = yield call(request, requestURL, headers);

            if (!isEmpty(response.data)) {
                data = true;

                // set the doc sign link
                yield put(actions.setDocSignStatus(response.data));
            } else if (response.status === 204) {
                // DocuSign envelope does not exist - Indicate this and wait 10 seconds
                yield put(actions.setDocumentsFetchSuccess(false));
                yield put(actions.setDocSignStatus(false));
                yield put(actions.showDocSignStatusLoader(false));
                yield delay(10000);
            }
        } catch (error) {
            console.log(error);
        } finally {
            attemptCounter++;
            yield put(actions.showDocSignStatusLoader(false));
        }
    }
}

// watcher saga for fetching the doc sign link
export function* getDocumentsDataSaga() {
    yield takeEvery(actionTypes.FETCH_DOCUMENTS_DATA, getDocumentsData);
}

// worker saga for fetching the the doc sign link
function* getDocumentsData() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    // set the endpoint
    const requestURL = api.documentsFetchURL; // rootAPIUrl + "/document/",
    let data = false;
    let attemptCounter = 0;

    while (!data) {
        //if docusign is not ready try again in 10 second until is done

        try {
            // show the loader

            if (!attemptCounter) {
                yield put(actions.showDocumentsLoader(true));
            }

            // get the response promise object
            const response = yield call(request, requestURL, headers);

            if (!isEmpty(response.data) && response.status === 200) {
                data = true;

                // set the doc sign link
                yield put(actions.setDocumentData(response.data));
            } else {
                // DocuSign envelope does not exist - Indicate this and wait 10 seconds
                yield put(actions.showDocumentsLoader(false));
                yield put(actions.setDocumentsFetchSuccess(false));
                yield delay(10000);
            }
        } catch (error) {
            console.log(error);
            yield delay(10000);
        } finally {
            attemptCounter++;
            // hide the loader regardless
            yield put(actions.showDocumentsLoader(false));
        }
    }
}

// watcher saga for fetching the doc sign details
export function* getDocSignDetailsSaga() {
    yield takeEvery(actionTypes.FETCH_DOCUMENTS_DATA, getDocSignDetails);
}

// worker saga for fetching the the doc sign details
function* getDocSignDetails() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    // set the endpoint
    const requestURL = api.documentSigningDetails; // rootAPIUrl + "/document/signing-details/"

    try {
        // get the response promise object
        const response = yield call(request, requestURL, headers);

        if (!isEmpty(response.data)) {
            // set the doc sign details
            yield put(actions.setDocSignDetails(response.data.signers));
        }
    } catch (error) {
        console.log(error);
    }
}

// watcher saga for fetching the member documents
export function* getMemberDocumentsSaga() {
    yield takeEvery(actionTypes.FETCH_MEMBER_DOC_DETAILS, getMemberDocuments);
}

// worker saga for fetching the member documents
function* getMemberDocuments() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    // set the endpoint
    const requestURL = api.memberDocumentsFetchURL;

    try {
        // get the response promise object
        const response = yield call(request, requestURL, headers);

        if (!isEmpty(response.data)) {
            // set the member doc details
            yield put(actions.setMemberDocDetails(response.data.documentTree));
            yield put(actions.setMemberDocFolderLookup(response.data.folderLookup));
        }
    } catch (error) {
        console.log(error);
    }
}

// watcher saga for fetching the member document categories
export function* getMemberDocumentCategoriesSaga() {
    yield takeEvery(actionTypes.FETCH_MEMBER_DOC_CATEGORIES, getMemberDocumentCategories);
}

// worker saga for fetching the member documents
function* getMemberDocumentCategories() {
    // set the headers
    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
    };

    // set the endpoint
    const requestURL = api.memberDocumentCategoriesFetchURL;

    try {
        // get the response promise object
        const response = yield call(request, requestURL, headers);

        if (!isEmpty(response.data)) {
            // set the member doc details
            yield put(actions.setMemberDocCategories(response.data));
        }
    } catch (error) {
        console.log(error);
    }
}
