// documentsReducer
export const FETCH_DOC_SIGN_STATUS= 'FETCH_DOC_SIGN_STATUS';
export const SET_DOC_SIGN_STATUS= 'SET_DOC_SIGN_STATUS';
export const FETCH_DOCUMENTS_DATA= 'FETCH_DOCUMENTS_DATA';
export const SET_DOCUMENTS_DATA= 'SET_DOCUMENTS_DATA';
export const SET_DOCUMENTS_LOADER= 'SET_DOCUMENTS_LOADER';
export const SET_DOCUMENTS_FETCH_SUCCESS= 'SET_DOCUMENTS_FETCH_SUCCESS';
export const SET_DOC_SIGN_STATUS_LOADER= 'SET_DOC_SIGN_STATUS_LOADER';
export const FETCH_DOC_SIGN_DETAILS= 'FETCH_DOC_SIGN_DETAILS';
export const SET_DOC_SIGN_DETAILS= 'SET_DOC_SIGN_DETAILS';
export const FETCH_MEMBER_DOC_DETAILS= 'FETCH_MEMBER_DOC_DETAILS';
export const SET_MEMBER_DOC_DETAILS= 'SET_MEMBER_DOC_DETAILS';
export const FETCH_MEMBER_DOC_CATEGORIES= 'FETCH_MEMBER_DOC_CATEGORIES';
export const SET_MEMBER_DOC_CATEGORIES= 'SET_MEMBER_DOC_CATEGORIES';
export const SET_MEMBER_DOC_FOLDER_LOOKUP= 'SET_MEMBER_DOC_FOLDER_LOOKUP';