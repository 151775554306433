import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// style import
import './style.scss'


const Overlay = (props) => {
    const {onCloseClickOverLay,layoutProps} = props
    const {openLeftMenu,openRightMenu } = layoutProps 

    const dynClass = cx('overlay', { 'overlay--show': openLeftMenu || openRightMenu });

    return <div className={dynClass} onClick={() => onCloseClickOverLay}></div>;
};

// define the prop types
Overlay.propTypes = {
    onCloseClickOverLay: PropTypes.func,
    layoutProps:PropTypes.object
};



export default Overlay;

