// investmentReducers
 export const SET_INVESTMENT_LOADER = 'SET_INVESTMENT_LOADER';
 export const FETCH_INVESTMENT_DATA = 'FETCH_INVESTMENT_DATA';
 export const SET_INVESTMENT_DATA = 'SET_INVESTMENT_DATA';
 export const SET_INVESTMENT_FETCH_SUCCESS = 'SET_INVESTMENT_FETCH_SUCCESS';

// portfolioReducers
export const SET_PORTFOLIO_LOADER = 'SET_PORTFOLIO_LOADER';
export const FETCH_PORTFOLIO_DATA = 'FETCH_PORTFOLIO_DATA';
export const SET_PORTFOLIO_DATA = 'SET_PORTFOLIO_DATA';
export const SET_PORTFOLIO_FUND_VALUE = 'SET_PORTFOLIO_FUND_VALUE';
export const SET_PORTFOLIO_FETCH_SUCCESS = 'SET_PORTFOLIO_FETCH_SUCCESS';

// transactionReducers
export const SET_TRANSACTION_LOADER = 'SET_TRANSACTION_LOADER';
export const SET_TRANSACTION_START_DATE = 'SET_TRANSACTION_START_DATE';
export const SET_TRANSACTION_END_DATE = 'SET_TRANSACTION_END_DATE';
export const FETCH_TRANSACTION_DATA = 'FETCH_TRANSACTION_DATA';
export const SET_TRANSACTION_FETCH_SUCCESS = 'SET_TRANSACTION_FETCH_SUCCESS';
export const SET_TRANSACTION_DATA = 'SET_FUSET_TRANSACTION_DATAND_TO_RESUME_RESET';
export const SET_TRANSACTION_CLOSING_BALANCE = 'SET_TRANSACTION_CLOSING_BALANCE';
export const SET_TRANSACTION_CLOSING_BALANCE_RESET = 'SET_TRANSACTION_CLOSING_BALANCE_RESET';
export const CLEAR_DATE_RANGE = 'CLEAR_DATE_RANGE';

// newsReducers
export const SET_NEWS_LOADER = 'SET_NEWS_LOADER';
export const FETCH_NEWS_DATA ='FETCH_NEWS_DATA';
export const SET_NEWS_DATA = 'SET_NEWS_DATA';
  
  
   