
// get requestsReducer
export const SET_REQUESTS_LOADER= 'SET_REQUESTS_LOADER'; 
export const FETCH_REQUESTS_DATA= 'FETCH_REQUESTS_DATA';
export const SET_REQUESTS_DATA= 'SET_REQUESTS_DATA';
export const SET_REQUESTS_FETCH_SUCCESS= 'SET_REQUESTS_FETCH_SUCCESS';
export const RESET_REQUESTS_DATA= 'RESET_REQUESTS_DATA';

// get requestsDetailReducer
export const SET_REQUEST_DETAIL_LOADER= 'SET_REQUEST_DETAIL_LOADER'; 
export const FETCH_REQUEST_DETAIL_DATA= 'FETCH_REQUEST_DETAIL_DATA';
export const SET_REQUEST_DETAIL_DATA= 'SET_REQUEST_DETAIL_DATA';
export const SET_REQUEST_DETAIL_FETCH_SUCCESS= 'SET_REQUEST_DETAIL_FETCH_SUCCESS';
export const RESET_REQUEST_DETAIL= 'RESET_REQUEST_DETAIL';

// set requestsDetailAsCompleteReducer
export const GET_REQUEST_TO_COMPLETE_DATA= 'GET_REQUEST_TO_COMPLETE_DATA';
export const SET_REQUEST_TO_COMPLETE_LOADER= 'SET_REQUEST_TO_COMPLETE_LOADER'; 
export const SET_REQUEST_TO_COMPLETE_SUCCESS= 'SET_REQUEST_TO_COMPLETE_SUCCESS';
export const RESET_REQUEST_TO_COMPLETE= 'RESET_REQUEST_TO_COMPLETE';

// set DocumentAsCompleteReducer
export const GET_DOCUMENT_TO_COMPLETE_DATA= 'GET_DOCUMENT_TO_COMPLETE_DATA';
export const SET_DOCUMENT_TO_COMPLETE_LOADER= 'SET_DOCUMENT_TO_COMPLETE_LOADER'; 
export const SET_DOCUMENT_TO_COMPLETE_SUCCESS= 'SET_DOCUMENT_TO_COMPLETE_SUCCESS';
export const RESET_DOCUMENT_TO_COMPLETE= 'RESET_DOCUMENT_TO_COMPLETE';

//Remove File Reducer
export const GET_REMOVE_DOCUMENT_FILE= 'GET_REMOVE_DOCUMENT_FILE';
export const SET_REMOVING_DOCUMENT_FILE_LOADER= 'SET_REMOVING_DOCUMENT_FILE_LOADER';
export const SET_REMOVE_DOCUMENT_FILE_SUCCESS= 'SET_REMOVE_DOCUMENT_FILE_SUCCESS';
export const RESET_REMOVE_DOCUMENT_FILE= 'RESET_REMOVE_DOCUMENT_FILE';