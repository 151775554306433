/**
* this file contains the array for the topbar & left menu component
*
* Created by Tom on 28/5/17
*/

import {endpoints} from '__utils/constants'

const menuItems = [
    {
        menuIcon: null,
        menuText: 'ABOUT',
        isExternal: true,
        openInNewTab: true,
        route: endpoints.websiteAboutURL
    },
    {
        menuIcon: null,
        menuText: 'FAQS',
        isExternal: true,
        openInNewTab: true,
        route: endpoints.websiteFAQsURL
    },
    {
        menuIcon: null,
        menuText: 'HOW IT WORKS',
        isExternal: true,
        openInNewTab: true,
        route: endpoints.websiteHowItWorksURL
    },
    {
        menuIcon: null,
        menuText: 'MY ACCOUNT',
        isExternal: false,
        route: '/account'
    },
    {
        menuIcon: null,
        menuText: 'SIGN OUT',
        isExternal: true,
        route: endpoints.websiteSignOutURL
    },
    {
        menuIcon: null,
        menuText: 'DASHBOARD',
        isExternal: false,
        openInNewTab: false,
        route: '/'
    },
]

export default menuItems
