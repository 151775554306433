import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// style import
import './style.scss'


const SimpleLoader = (props) => {
    const { show, title, darkMode, num } = props;

    return (
        show && (
            <div className={cx('simple-loader', { 'simple-loader--dark-mode': darkMode })}>
                {title && <div className="simple-loader__title">{title}</div>}
                {[...new Array(num)].map((d, index) => (
                    <div key={index} className="simple-loader__section">
                        <div className="animated-bg animated-bg--short"></div>
                        <div className="animated-bg animated-bg--long"></div>
                        <div className="animated-bg animated-bg--long"></div>
                        <div className="animated-bg animated-bg--long"></div>
                    </div>
                ))}
            </div>
        )
    );
};

export default SimpleLoader;

// define the prop types
SimpleLoader.propTypes = {
    num: PropTypes.number.isRequired,
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    darkMode: PropTypes.bool,
};
