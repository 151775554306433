import React from 'react';
import PropTypes from 'prop-types'
import AppLink from 'components/AppLink'
import TopBarMenuItem from './TopBarMenuItem'

// top bar menu items import
import menuItems from './menuItems'

// icon imports
import Logo from 'assets/svg/selfmade_logo.svg'
import AvatarIcon from 'assets/svg/avatar.svg'
import ArrowIcon from 'assets/svg/arrow_blue.svg'
import LockIcon from 'assets/svg/lock.svg'
import Hamburger from 'assets/svg/hamburger.svg'

// style import
import './style.scss'
import { useLocation } from 'react-router-dom';

const TopBar = (props) => {
  
    const location = useLocation();
    const { pathname } = location;
    const {onBurgerMenuClick} = props

    return (
        <div className="top-bar">

            {/* selfmade superfund logo */}
            {/* Logo need to be clickable back to dashboard */}
            <AppLink route={menuItems[5].route} >
                <img
                    src={Logo}
                    className="top-bar__logo"
                    alt="Selfmade superfund logo" />
            </AppLink>

            {/* topbar menu items and icons */}
            <div className="top-bar__menu-items">

                <TopBarMenuItem
                    menuItem={menuItems[0]}
                    pathname={pathname}
                />

                <TopBarMenuItem
                    menuItem={menuItems[1]}
                    pathname={pathname}
                />

                <TopBarMenuItem
                    menuItem={menuItems[2]}
                    pathname={pathname}
                />

                <div className="top-bar__menu-items__icon-wrapper-left">

                    <TopBarMenuItem
                        menuItem={menuItems[3]}
                        pathname={pathname}
                        leftIcon={AvatarIcon}
                        leftIconClass={"top-bar__menu-items__icon-wrapper-left__acc"}
                        leftIconAlt="Avatar icon"
                        rightIcon={ArrowIcon}
                        rightIconClass="top-bar__menu-items__icon-wrapper-left__arrow"
                        rightIconAlt="Arrow icon"
                    />

                    <TopBarMenuItem
                        menuItem={menuItems[4]}
                        pathname={pathname}
                        leftIcon={LockIcon}
                        leftIconClass="top-bar__menu-items__icon-wrapper-left__lock"
                        leftIconAlt="Lock icon"
                    />

                </div>
            </div>

            {/* hamburger icon */}
            <img
                src={Hamburger}
                className="top-bar__hamburger"
                alt="Mobile view hamburger icon"
                onClick={onBurgerMenuClick}/>
        </div>
    )
}

// define the prop types
TopBar.propTypes = {
    onBurgerMenuClick: PropTypes.func,
};




export default TopBar
