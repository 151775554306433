import React from 'react'
import cx from 'classnames'
import Parser from 'html-react-parser'
import {isEmpty} from '__utils/utilities'
import PropTypes from 'prop-types'
// component import
import PendingActionItem from './PendingActionItem'
import SimpleLoader from 'components/SimpleLoader'
// style import
import './style.scss'
import BellIcon from 'assets/svg/bell.svg'
import CloseIcon from 'assets/svg/close.svg'
import CongratsImg from 'assets/svg/congrats.svg'
import SpeakImg from 'assets/svg/speak_directly.svg'
import IdeaImg from 'assets/svg/dollar-idea.svg'
import GreenTick from 'assets/svg/green_tick.svg'
//redux
import { useDispatch, useSelector } from 'react-redux';
import {notificationsSlr,referralSlr} from './__redux/selectors'
import { dismissNotificationRequest,
    removeNotificationsPendingAction,
    sendReferralRequest,
    setReferralResponse,
} from './__redux/actions' 
import {getProfileSlr} from '__pages/Account/__redux/selectors'

//import AppButton from '../AppButton'
import Popup from "reactjs-popup";


const RightMenu = (props) => {
    const {onOpenCloseRightMenu, onCloseCongratsInfoBox : _onCloseCongratsInfoBox,layoutProps} = props
    const {openCongratsInfo, openRightMenu } = layoutProps
    const dispatch = useDispatch();
    const [email, setEmail] = React.useState();
    const { congratulateMessage, pendingActions, newsItems, isLoading } = useSelector(notificationsSlr);
    const { data: profileData } = useSelector(getProfileSlr);
    const { id:profileId , email : profileEmail} = profileData
    const { isEmailing, response: referralResponse } = useSelector(referralSlr);


    const onCloseCongratsInfoBox = () => {
        const congratsId = congratulateMessage.id;

        //close the congratulation info box
        _onCloseCongratsInfoBox()

        // Call api to dismiss this notification
        dispatch(dismissNotificationRequest(congratsId));
    };

    const onHandleChange = (e) => {
        setEmail(e.target.value);
    };

    const onSendReferralEmail = () => {
        if (email !== '') {
            let details = {
                id:profileId,
                email_string: email,
            };

            dispatch(setReferralResponse(''));
            dispatch(sendReferralRequest(details));
        }
    };

    const onRemovePendingActionItem = (pendingActionId) => {
        // Reomve pending action immediately from screen
        dispatch(removeNotificationsPendingAction(pendingActionId));

        // Call api to dismiss this pending action
        dispatch(dismissNotificationRequest(pendingActionId));
    };

    const renderCongrats = () => {
        if (isEmpty(congratulateMessage)) return;

        const { title, sub_title, description } = congratulateMessage;

        return (
            openCongratsInfo && (
                <div className="right-menu__top-container">
                    <div className="right-menu__top-container__close-text" onClick={onCloseCongratsInfoBox}>
                        Close
                    </div>
                    <div className="right-menu__wrapper">
                        <div className="right-menu__wrapper-left">
                            <img src={CongratsImg} alt="Man on top podium" />
                        </div>
                        <div className="right-menu__wrapper-right">
                            <p className="right-menu__header-text">{title}</p>
                            <p className="right-menu__info-bold">{sub_title}</p>
                            <div className="right-menu__info-text">{Parser(description)}</div>
                        </div>
                    </div>
                </div>
            )
        );
    };

    const renderPendingActions = () => {
        if (isEmpty(pendingActions)) return;

        return (
            <div className="right-menu__middle-container">
                <p className="right-menu__header-text">PENDING ACTIONS</p>

                {pendingActions.map((item) => (
                    <PendingActionItem
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        link1={{
                            text: item.link_text1,
                            url: item.link_url1,
                        }}
                        link2={{
                            text: item.link_text2,
                            url: item.link_url2,
                        }}
                        show_dismiss={item.show_dismiss}
                        onRemove={() => onRemovePendingActionItem(item.id)}
                    />
                ))}
            </div>
        );
    };

    const renderNews = () => {
        if (isEmpty(newsItems)) return;

        return (
            <React.Fragment>
                {newsItems.map((item) => {
                    const id = item.id;
                    const img = item.image || SpeakImg;
                    const title = item.title;
                    const description = item.description;

                    return (
                        <div className="right-menu__bottom-container" key={id}>
                            <div className="right-menu__wrapper">
                                <div className="right-menu__wrapper-left">
                                    <img src={img} alt="" />
                                </div>
                                <div className="right-menu__wrapper-right">
                                    <p className="right-menu__header-text">{title}</p>
                                    <div className="right-menu__info-text">{Parser(description)}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </React.Fragment>
        );
    };

    const renderReferral = () => {
        let isDemo = false;
        if (profileEmail === 'demo+1865@selfmade.com.au') {
            isDemo = true;
        }
        if (isDemo) return;

        let title = 'Refer a Friend';
        let description = 'Receive 10% off your yearly membership fees for a successful referral.';

        return (
            <div className="right-menu__referral-container">
                <div className="right-menu__wrapper">
                    <div className="right-menu__wrapper-left">
                        <img src={IdeaImg} alt="Documents" />
                    </div>
                    <div className="right-menu__wrapper-right">
                        <p className="right-menu__header-text">{title}</p>
                        <div className="right-menu__info-text">
                            {Parser(description)}
                            <Popup
                                trigger={<span className="tooltip-link">More Info?</span>}
                                position="bottom center"
                                contentStyle={{ width: '300px', padding: '20px' }}
                            >
                                <div>
                                    <div className="popup-data">
                                        <p>
                                            Refer a friend to Selfmade, and if they on-board successfully, you are both
                                            eligible to receive 10% off your yearly membership fees for 12 months.
                                            Discount is applied from next calendar month following successful set up of
                                            fund.
                                        </p>
                                    </div>
                                </div>
                            </Popup>
                        </div>
                        <div className="right-menu__info-text">
                            <input
                                onChange={(e) => onHandleChange(e)}
                                className={
                                    'right-menu__referral-container--input' +
                                    (referralResponse === 'INVALID' ? ' invalid-email' : '')
                                }
                                type="text"
                                placeholder="friend@example.com"
                            />

                            <div className="right-menu__referral-container--button">
                                <button
                                    disabled={isEmailing}
                                    className={
                                        'app-button app-button--small app-button--blue loading-spinner' +
                                        (isEmailing ? '' : ' loading-spinner--hide')
                                    }
                                    onClick={() => onSendReferralEmail()}
                                >
                                    Send Referral
                                    {referralResponse === 'SUCCESS' && (
                                        <img
                                            src={GreenTick}
                                            className="right-menu__referral-container--success"
                                            alt="Email Sent Successfully"
                                        />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const dynClass = cx('right-menu-container', { 'right-menu-container--open': openRightMenu });

    return (
        <div className="right-menu-container-wrapper">
            <div className="right-menu-open-container" onClick={() => onOpenCloseRightMenu(true)}>
                <img src={BellIcon} className="right-menu-open-container__icon" alt="Left menu close icon" />
            </div>
            <div className={dynClass}>
                <div className="right-menu-wrapper">
                    <div className="right-menu-container__close-container" onClick={() => onOpenCloseRightMenu(false)}>
                        <img src={CloseIcon} className="right-menu-container__close-icon" alt="Left menu close icon" />
                    </div>
                    <div className="right-menu">
                        <SimpleLoader num={2} show={isLoading} darkMode />

                        {/* referral link */}
                        {renderReferral()}

                        {/* congrats info box */}
                        {renderCongrats()}

                        {/* pending actions container */}
                        {renderPendingActions()}

                        {/* bottom info container */}
                        {renderNews()}
                    </div>
                </div>
            </div>
        </div>
    );
};

// define the prop types
RightMenu.propTypes = {
    layoutProps: PropTypes.object,
    onOpenCloseRightMenu: PropTypes.func,
    onCloseCongratsInfoBox: PropTypes.func,
};


export default RightMenu;
