import * as actionTypes from './actionTypes';


const initState = {
    fundInfoData: {},
    membersTrusteesInfoData: [],
    companyDetailsData: {},
    isLoading: false,
};

export const fundState = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SET_FUND_INFO_DATA:
            const fundInfoData = {
                fundName: action.payload['fundName'],
                fundType: action.payload['fundType'],
                jurisdiction: action.payload['jurisdiction'],
                accountName: action.payload['investmentDetails']['accountName'],
                accountNumber: action.payload['investmentDetails']['accountNumber'],
                bsb: action.payload['investmentDetails']['bsb'],
                abn: action.payload['abn'] ? action.payload['abn'] : 'ABN registration in progress',
                tfn: action.payload['tfn'],
            };
            return {
                ...state,
                fundInfoData: fundInfoData,
            };

        case actionTypes.SET_BASIC_FUND_INFO_DATA:
            return {
                ...state,
                basicFundInfoData: action.payload,
            };

        case actionTypes.SET_MEMBERS_TRUSTEES_DATA:
            return {
                ...state,
                membersTrusteesInfoData: getMemberTrusteeData(action.payload),
            };

        case actionTypes.SET_COMPANY_DETAILS_DATA:
            const companyDetailsData = {
                companyName: action.payload['companyDetails'] ? action.payload['companyDetails']['companyName'] : '',
                acn: action.payload['companyDetails'] ? action.payload['companyDetails']['acn'] : '',
            };
            return {
                ...state,
                companyDetailsData: companyDetailsData,
            };

        case actionTypes.SET_FUND_LOADER:
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
};

// get the title of the member/truess
function getMemberTrusteeTitle(index, fundData) {
    let fundType = fundData['fundType'] === 'INDIVIDUAL' ? 'Trustee' : 'Director';

    switch (index) {
        // Trustee 2 is the special case.
        // When fundData['trustees'] is not empty, meaning the Trustee 2 is not a member
        case 1:
            if (fundData['trustees'].length === 0) {
                return `Member & ${fundType} ${index + 1}`;
            } else {
                return `${fundType} ${index + 1}`;
            }

        default:
            return `Member & ${fundType} ${index + 1}`;
    }
}

// get refined data of member truste
function getMemberTrusteeData(fundData) {
    let allMemberTrustee = fundData['members'].concat(fundData['trustees']);
    const fundMembersTrusteesData = allMemberTrustee.map((ele, index) => {
        return {
            heading: getMemberTrusteeTitle(index, fundData),
            data: ele,
        };
    });

    return fundMembersTrusteesData;
}
