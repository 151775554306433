 import { all } from 'redux-saga/effects'
 import * as DashboardSaga from '__pages/Dashboard/__redux/sagas'
 import * as AccountSaga from '__pages/Account/__redux/sagas'
 import * as RequestSaga from '__pages/Requests/__redux/sagas'
 import * as RequestDetailSaga from '__pages/Requests/RequestDetail/__redux/sagas'
 import * as FundSaga from '__pages/Fund/__redux/sagas'
 import * as DocumentsSaga from '__pages/Documents/__redux/sagas'
 import * as BillingSaga from '__pages/Billing/__redux/sagas'
 import * as RightMenuSaga from '__layout/RightMenu/__redux/sagas'
 
 /* -----------------------------------------------------------------------------
     ROOT SAGA
 ----------------------------------------------------------------------------- */
 
 // this root saga groups all other watcher sagas together
 export default function* rootSaga() {
     yield all([
         DashboardSaga.getTransactionSaga(),
         DashboardSaga.clearDateRangeSaga(),
         DashboardSaga.getInvestmentSaga(),
         DashboardSaga.getPortfolioSaga(),
         DashboardSaga.getNewsSaga(),
         AccountSaga.getProfileSaga(),
         AccountSaga.editBrandSaga(),
         RequestSaga.getRequestsDataSaga(),
         RequestSaga.fetchRequestsSummarySaga(),
         RequestDetailSaga.getRequestDetailsSaga(),
         RequestDetailSaga.setRequestToCompleteSaga(),
         RequestDetailSaga.setDocumentToCompleteSaga(),
         RequestDetailSaga.removeDocFileSaga(),
         FundSaga.getFundSaga(),
         FundSaga.getBasicFundSaga(),
         DocumentsSaga.getDocSignStatusSaga(),
         DocumentsSaga.getDocumentsDataSaga(),
         DocumentsSaga.getDocSignDetailsSaga(),
         DocumentsSaga.getMemberDocumentsSaga(),
         DocumentsSaga.getMemberDocumentCategoriesSaga(),
         BillingSaga.getBillingDataSaga(),
         RightMenuSaga.getNotificationsSaga(),
         RightMenuSaga.dismissNotificationSaga(),
         RightMenuSaga.watchSendReferral(),
         
        
     ])
 }
 